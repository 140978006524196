import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { UserImageList } from 'src/app/dto/UserImageCollection';
import { ViewExternalService } from 'src/app/private/profile/view/view.component.service';

@Component({
  selector: 'other-user-image-details',
  templateUrl: './other-user-image-details.component.html',
  styleUrl: './other-user-image-details.component.css'
})
export class OtherUserImageDetailsComponent {
userWhosInformationIsRequired: string;
  userloggedIn: string;
  showLoader = true;
  showLargeImage = false;
  public images: UserImageList;
  imageToDisplay: HostWindowModel;
  mainImage: string = "";
  userId: string;
  displayCustom: boolean | undefined;
  activeIndex: number = 0;
  responsiveOptions: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  constructor(private commonservice: Commonservice, private viewExtService: ViewExternalService, private localStore: LocalStore, private _Activatedroute: ActivatedRoute, private signalStateService: SignalStateService) { }

  ngOnInit() {
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;     
    });
    this._Activatedroute.params.subscribe(params => {
      self.userWhosInformationIsRequired = params['id'];
      self.getUserInfo();
    });   
  }

  async getUserInfo() {
    const self = this;
    self.showLoader = true;
    self.commonservice.getUserImageCollection(self.userWhosInformationIsRequired).subscribe(
      data => {
        self.run(data);
      },
      error => { }
    );
  }

  public async run(data: any) {
    const self = this;
    self.images = data;
    self.showLoader = false;
  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  makeitbig(imageThumbnailId: string) {
    const self = this;
    if (imageThumbnailId != null) {
      let largerImageId = imageThumbnailId.replace("Tmb", "");
      self.commonservice.getLargeMainImage(largerImageId).subscribe(data => {
        self.showLargeImage = true;
        self.mainImage = data.MainImage;
      });
    }
    else {
    }
  }
}
