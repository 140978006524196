<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>


<p-fieldset *ngIf="showLocalLoader" legend="WHO VIEWED MY CONTACTS" style="min-height: 100px;">
  <local-loader></local-loader>
</p-fieldset>

<p-fieldset legend="WHO VIEWED MY CONTACTS" *ngIf="!showLocalLoader" @fadeInOut>


  <div [ngClass]="pagedItems?.length>0 ? 'card-container' : 'card-container-without-padding'">
    <p-card *ngFor="let item of pagedItems" class="card">
      <ng-template pTemplate="header">
        <div [ngClass]="[
        item?.IsImagePresent
          ? ''  
          : 'no-image-2'
      ]">

          <img [src]="item.ImagefPersonWhoVisitedMe" [ngClass]="[
          item.ImagefPersonWhoVisitedMe
            ? 'bookmark-image img-thumbnail'
            : 'bookmark-image-no-cursor'
        ]" (click)="
          makeitbig(                  
            item.ImageIdofPersonWhoVisitedMe,
              item.PersonWhoVisitedMe
          )
        " [@fadeScaleAnimation]="membersWhoViewedContactDetails" />
        </div>
      </ng-template>
      <ng-template pTemplate="body">

        <div class="flex">
          <div class="col-12">


            <div class="flex flex-row flex-wrap">

              <!-- this is for name -->
              <div class="col-10 user-name-title">{{item.NameOfPersonWhoVisitedMe }}</div>
              <!-- this is for name -->

              <!-- this is for book mark icon -->
              <div class="col-1" style="display: flex;justify-content: flex-end; text-align: right;">
                <div style="width: 100%;" pTooltip="Bookmark" tooltipPosition="top" tooltipStyleClass="custom-tooltip"
                  (click)="bookmarkprofile(item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                    stroke="#FF7F6B" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-bookmark-plus zoom-icon add-cursor">
                    <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z" />
                    <line x1="12" x2="12" y1="7" y2="13" />
                    <line x1="15" x2="9" y1="10" y2="10" />
                  </svg>
                </div>
              </div>
              <div class="col-1">
                <div pTooltip="Chat" tooltipPosition="bottom" (click)="invokeChat(item)"
                  tooltipStyleClass="custom-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                    stroke="#2DD4BF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-message-circle zoom-icon add-cursor">
                    <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
                  </svg>
                </div>

              </div>
              <!-- this is for book mark icon -->

            </div>

            <div class="flex flex-row flex-wrap">
              <div class="col-1"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                  fill="none" stroke="#7DD3FC" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-calendar-days">
                  <path d="M8 2v4" />
                  <path d="M16 2v4" />
                  <rect width="18" height="18" x="3" y="4" rx="2" />
                  <path d="M3 10h18" />
                  <path d="M8 14h.01" />
                  <path d="M12 14h.01" />
                  <path d="M16 14h.01" />
                  <path d="M8 18h.01" />
                  <path d="M12 18h.01" />
                  <path d="M16 18h.01" />
                </svg></div>
              <div class="col-11 book-mark-id">
                {{ item.DatePersonVisitedMe | date }}
              </div>
            </div>


            <div class="flex flex-row flex-wrap">
              <div class="col-1">
                <span class="font-semibold"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    viewBox="0 0 24 24" fill="none" stroke="#FBC1E0" stroke-width="1" stroke-linecap="round"
                    stroke-linejoin="round" class="lucide lucide-id-card">
                    <path d="M16 10h2" />
                    <path d="M16 14h2" />
                    <path d="M6.17 15a3 3 0 0 1 5.66 0" />
                    <circle cx="9" cy="11" r="2" />
                    <rect x="2" y="5" width="20" height="14" rx="2" />
                  </svg> </span>
              </div>
              <div class="col-5 book-mark-id pl-2">{{item.PersonWhoVisitedMe }}</div>

              <div class="col-1">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="#009688" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-church">
                  <path d="M10 9h4" />
                  <path d="M12 7v5" />
                  <path d="M14 22v-4a2 2 0 0 0-4 0v4" />
                  <path
                    d="M18 22V5.618a1 1 0 0 0-.553-.894l-4.553-2.277a2 2 0 0 0-1.788 0L6.553 4.724A1 1 0 0 0 6 5.618V22" />
                  <path
                    d="m18 7 3.447 1.724a1 1 0 0 1 .553.894V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.618a1 1 0 0 1 .553-.894L6 7" />
                </svg>

              </div>
              <div class="col-5 candidate-denomination">
                {{ item.Denomination }}
              </div>
            </div>


            <div class="flex flex-row flex-wrap">

              <div class="col-1">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="#C4B6FD" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-map">
                  <path
                    d="M14.106 5.553a2 2 0 0 0 1.788 0l3.659-1.83A1 1 0 0 1 21 4.619v12.764a1 1 0 0 1-.553.894l-4.553 2.277a2 2 0 0 1-1.788 0l-4.212-2.106a2 2 0 0 0-1.788 0l-3.659 1.83A1 1 0 0 1 3 19.381V6.618a1 1 0 0 1 .553-.894l4.553-2.277a2 2 0 0 1 1.788 0z" />
                  <path d="M15 5.764v15" />
                  <path d="M9 3.236v15" />
                </svg>

              </div>
              <div class="col-5 candidate-location">
                {{ item.State }}
              </div>

              <div class="col-1">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="#009688" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-church">
                  <path d="M10 9h4" />
                  <path d="M12 7v5" />
                  <path d="M14 22v-4a2 2 0 0 0-4 0v4" />
                  <path
                    d="M18 22V5.618a1 1 0 0 0-.553-.894l-4.553-2.277a2 2 0 0 0-1.788 0L6.553 4.724A1 1 0 0 0 6 5.618V22" />
                  <path
                    d="m18 7 3.447 1.724a1 1 0 0 1 .553.894V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.618a1 1 0 0 1 .553-.894L6 7" />
                </svg>

              </div>
              <div class="col-5 candidate-education">
                {{ item.Education }}
              </div>


            </div>

          </div>

        </div>


      </ng-template>

      <ng-template pTemplate="footer">

        <hr class="hr-bottom-style" />

        <div style="display: flex;justify-content: center;">



          <button class="custom-button profile-button" (click)="navigateToDetails( item)" pTooltip="VIEW PROFILE"
            tooltipPosition="top" tooltipStyleClass="custom-tooltip">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
              class="lucide lucide-contact">
              <path d="M16 2v2" />
              <path d="M7 22v-2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2" />
              <path d="M8 2v2" />
              <circle cx="12" cy="11" r="3" />
              <rect x="3" y="4" width="18" height="18" rx="2" />
            </svg>
            <span> Profile</span>
          </button>





          <button class="custom-button propose-button text-sky-600 bg-sky-50" pTooltip="PROPOSE" tooltipPosition="top"
            (click)="expressInterestPop($event, item)" tooltipStyleClass="custom-tooltip">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
              class="lucide lucide-hand-heart">
              <path d="M11 14h2a2 2 0 1 0 0-4h-3c-.6 0-1.1.2-1.4.6L3 16" />
              <path d="m7 20 1.6-1.4c.3-.4.8-.6 1.4-.6h4c1.1 0 2.1-.4 2.8-1.2l4.6-4.4a2 2 0 0 0-2.75-2.91l-4.2 3.9" />
              <path d="m2 15 6 6" />
              <path
                d="M19.5 8.5c.7-.7 1.5-1.6 1.5-2.7A2.73 2.73 0 0 0 16 4a2.78 2.78 0 0 0-5 1.8c0 1.2.8 2 1.5 2.8L16 12Z" />
            </svg>
            <span> Propose</span>
          </button>

          <button class="custom-button message-button" (click)="showSendDialogue( item)" pTooltip="Send Custom message"
            tooltipPosition="top" tooltipStyleClass="custom-tooltip">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
              class="lucide lucide-message-square-text">
              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
              <path d="M13 8H7" />
              <path d="M17 12H7" />
            </svg>
            <div> Message</div>
          </button>


          <button class="custom-button delete-button" pTooltip="DELETE" tooltipPosition="top" (click)="deleteItem(item)"
            tooltipStyleClass="custom-tooltip">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
              stroke="#6c757d" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
              class="lucide lucide-trash-2">
              <path d="M3 6h18" />
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
              <line x1="10" x2="10" y1="11" y2="17" />
              <line x1="14" x2="14" y1="11" y2="17" />
            </svg>
            <div> Delete</div>
          </button>


        </div>

      </ng-template>

    </p-card>
  </div>


  <div style="margin-top: 10px; text-align: center" *ngIf="membersWhoViewedContactDetails?.length > 0">
    <p-paginator [rows]="rows" [totalRecords]="totalRecords" (onPageChange)="paginate($event)">
    </p-paginator>
  </div>

  <div *ngIf="membersWhoViewedContactDetails?.length === 0" class="no-value-text">
    No members viewed your contact details yet
  </div>




  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>


  <p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
    <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
      (OnSubmit)="eventPostSubmit($event)"></send-message>
  </p-dialog>

  <p-dialog [(visible)]="viewProfileVisible" [modal]="true" [closable]="true" [style]="{width: '80vw', height:'83vh'}">
    <ng-template pTemplate=header>
      <div class="pl-5 other-prifle-title">{{nameOfPersonsProfileBeingSeen}}</div>
    </ng-template>
    <div *ngIf="viewProfileVisible">
      <app-viewotherprofile [userId]="selectedUserId"></app-viewotherprofile>
    </div>
  </p-dialog>

  <p-confirmPopup></p-confirmPopup>
  <p-toast position="bottom-right"></p-toast>

</p-fieldset>