<div class="image-block">

    <div class="pr-3" pTooltip="VIEW PROFILE" style="font-size: 1.1rem" tooltipPosition="left" (click)="viewProfile()"
        tooltipStyleClass="custom-tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#1E88E5"
            stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-contact zoom-icon add-cursor">
            <path d="M16 2v2" />
            <path d="M7 22v-2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2" />
            <path d="M8 2v2" />
            <circle cx="12" cy="11" r="3" />
            <rect x="3" y="4" width="18" height="18" rx="2" />
        </svg>
    </div>
</div>

<div class="image-block">
    <div class="pr-3" style="font-size: 1.1rem" pTooltip="Express Interest" tooltipPosition="left"
        (click)="expressInterest()" tooltipStyleClass="custom-tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#E91E63"
            stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-hand-heart zoom-icon add-cursor">
            <path d="M11 14h2a2 2 0 1 0 0-4h-3c-.6 0-1.1.2-1.4.6L3 16" />
            <path d="m7 20 1.6-1.4c.3-.4.8-.6 1.4-.6h4c1.1 0 2.1-.4 2.8-1.2l4.6-4.4a2 2 0 0 0-2.75-2.91l-4.2 3.9" />
            <path d="m2 15 6 6" />
            <path
                d="M19.5 8.5c.7-.7 1.5-1.6 1.5-2.7A2.73 2.73 0 0 0 16 4a2.78 2.78 0 0 0-5 1.8c0 1.2.8 2 1.5 2.8L16 12Z" />
        </svg>

    </div>
</div>
<div class="image-block">
    <div class="pr-3" pTooltip="Send message" style="font-size: 1.1rem" tooltipPosition="left"
        (click)="showSendMessage()" tooltipStyleClass="custom-tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#4CAF50"
            stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-message-square-text zoom-icon add-cursor">
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
            <path d="M13 8H7" />
            <path d="M17 12H7" />
        </svg>
    </div>

</div>


<div class="image-block">

    <div class="pr-3" pTooltip="Bookmark" style="font-size: 1.1rem" tooltipPosition="left" (click)="bookmarkprofile()"
        tooltipStyleClass="custom-tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FFC107"
            stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bookmark-plus zoom-icon add-cursor">
            <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z" />
            <line x1="12" x2="12" y1="7" y2="13" />
            <line x1="15" x2="9" y1="10" y2="10" />
        </svg>
    </div>
</div>

<div class="image-block">
    <div class="pr-3" pTooltip="Chat" style="font-size: 1.1rem" tooltipPosition="left" (click)="invokeChat()"
        tooltipStyleClass="custom-tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#2DD4BF"
            stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
            class="lucide lucide-message-circle zoom-icon add-cursor">
            <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
        </svg>
    </div>
</div>

<div class="image-block">
    <div class="pr-3" pTooltip="DELETE" style="font-size: 1.1rem" tooltipPosition="left" (click)="deleteRecord()"
        tooltipStyleClass="custom-tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FF5252"
            stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2 zoom-icon add-cursor">
            <path d="M3 6h18" />
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
            <line x1="10" x2="10" y1="11" y2="17" />
            <line x1="14" x2="14" y1="11" y2="17" />
        </svg>
    </div>
</div>

<p-dialog header="Send Message" [(visible)]="userAction.ShowSendMessage">
    <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
        (OnSubmit)="eventPostSubmit($event)"></send-message>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
<p-toast position="bottom-right"></p-toast>

<p-dialog [(visible)]="viewProfileVisible" [modal]="true" [closable]="true" [style]="{width: '80vw', height:'85vh'}">
    <ng-template pTemplate=header>
        <div class="pl-5 other-prifle-title">{{nameOfPersonsProfileBeingSeen}}</div>
    </ng-template>
    <div *ngIf="viewProfileVisible">
        <app-viewotherprofile [userId]="selectedUserId"></app-viewotherprofile>
    </div>
</p-dialog>