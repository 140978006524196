import { Dropdown2 } from "../../../dto/Dropdown2";
import { DeleteModel } from "../../../dto/DeleteModel";
import { DeleteResponseModel } from "../../../dto/DeleteResponseModel";
import { ExpressInterestSendModel } from "../../../dto/ExpressInterestSendModel";
import { SendMessageModelCsKind } from "../../../dto/SendMessageModelCsKind";
import { SendMessageGeneric } from "../../../dto/SendMessageGeneric";
import { ExpressInterestCarrier } from "../../../dto/ExpressInterestCarrier";
import { MemberShipInformationModel } from "../../../dto/MemberShipInformationModel";
import { ListOfusersBookMarkedMiniModel } from "../../../dto/ListOfusersBookMarkedMiniModel";
import { UserSavedSearchResult } from "../../../dto/UserSavedSearchResult";
import { MembersWhoViewedMyContactDetailsModel } from "../../../dto/MembersWhoViewedMyContactDetailsModel";
import { ContactDetailViewedByMeModel } from "../../../dto/ContactDetailViewedByMeModel";
import { RecentVisitorsToMyProfileModel } from "../../../dto/RecentVisitorsToMyProfileModel";
import { YourRecentVisitsModelOVC } from "../../../dto/YourRecentVisitsModelOVC";
import { NewlyCreatedUserResponseModel } from "../../../dto/NewlyCreatedUserResponseModel";
import { SearchResultByIdModel } from "../../../dto/SearchResultByIdModel";
import { UpdateResponseModel } from "../../../dto/UpdateResponseModel";
import { ResponseFromUserModel } from "../../../dto/ResponseFromUserModel";
import { UserVaultModel } from "../../../dto/UserVaultModel";
import { QuickSearchHomePageParam } from "../../../dto/QuickSearchHomePageParam";
import { BasicRegisterUserModel } from "../../../dto/BasicRegisterUserModel";
import { GetViewProfileBasicDto } from "../../../dto/GetViewProfileBasicDto";
import { GetViewProfileJobInformationDto } from "../../../dto/GetViewProfileJobInformationDto";
import { GetViewProfileFamilyDetailsDto } from "../../../dto/GetViewProfileFamilyDetailsDto";
import { GetViewProfilePostDto } from "../../../dto/GetViewProfilePostDto";
import { GetViewProfilePictureDto } from "../../../dto/GetViewProfilePictureDto";
import { GetViewProfileOtherDto } from "../../../dto/GetViewProfileOtherDto";
import { GetViewProfilePartnerPreferencesDto } from "../../../dto/GetViewProfilePartnerPreferencesDto";
import { GetViewProfileReferencesInKeralaDto } from "../../../dto/GetViewProfileReferencesInKeralaDto";
import { GetEditProfileBasicDto } from "../../../dto/GetEditProfileBasicDto";
import { GetEditProfileJobDto } from "../../../dto/GetEditProfileJobDto";
import { GetEditProfileContactDto } from "../../../dto/GetEditProfileContactDto";
import { GetEditProfileFamilyDto } from "../../../dto/GetEditProfileFamilyDto";
import { GetEditProfilePartnerMegaDto } from "../../../dto/GetEditProfilePartnerMegaDto";
import { GetEditProfileReferenceDto } from "../../../dto/GetEditProfileReferenceDto";
import { GetEditProfileOtherDto } from "../../../dto/GetEditProfileOtherDto";
import { SetEditProfileReferenceDto } from "../../../dto/SetEditProfileReferenceDto";
import { SetEditProfileOtherDto } from "../../../dto/SetEditProfileOtherDto";
import { ViewOtherProfilePrimaryTab } from "../../../dto/ViewOtherProfilePrimaryTab";
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RegisteredUser } from 'src/app/private/profile/view/view.components.domain';
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs';
import { QuickSearchResult } from 'src/app/private/search/quicks/quicks.component.domain';
import { dropdown } from "src/app/dto/dropdown.1";
import { superUserMine } from "src/app/dto/superUserMine";
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { SaveResponse } from "src/app/dto/SaveResponse";
import { ExpressInterestModel } from "src/app/dto/ExpressInterestModel";
import { SendMessageModel } from "src/app/dto/SendMessageModel";
import { AddBookmarkModel } from "src/app/dto/AddBookmarkModel";
import { RemoveBookmarkModel } from "src/app/dto/RemoveBookmarkModel";
import { HomePageUserInfoModel } from "src/app/dto/HomePageUserInfoModel";
import { ImageCatalogueModel } from "src/app/dto/ImageCatalogueModel";
import { SaveResponseModel } from "src/app/dto/SaveResponseModel";
import { DatePipe } from '@angular/common';
import { SearchQueryBody } from 'src/app/private/search/advanced/advanced.component.domain';
import { CandidateReliionBackGround } from "src/app/private/profile/view/CandidateReliionBackGround";
import { ViewOtherProfilePartnerRequirementTab } from "src/app/dto/ViewOtherProfilePartnerRequirementTab";
import { ViewOtherEducationAndCareerTab } from "src/app/dto/ViewOtherEducationAndCareerTab";
import { MemberFamilyDetailsDto } from "src/app/private/profile/view/MemberFamilyDetailsDto";
import { MemberContactDetailsDto } from "src/app/dto/MemberContactDetailsDto";
import { MemberUserBoardDOtherViewto } from "src/app/dto/MemberUserBoardDto";
import { MessageInboxModel } from "src/app/dto/MessageInboxModel";
import { RegisterNewMemberModel } from "src/app/dto/RegisterNewMemberModel";
import { Dropdown } from "src/app/dto/Dropdown";
import { ContactUsDto } from "src/app/dto/ContactusDto";
import { UserImageList } from "src/app/dto/UserImageCollection";
import { MessagesToDelete } from "src/app/private/mymessages/inbox/MessagesToDelete";
import { LargerImageWithName } from "src/app/private/profile/view/LargerImageWithName";
import { ChatUserListResponse } from "src/app/dto/usersReadyForChat";
import { GetViewProfileWorkAddressDto } from "src/app/dto/GetViewProfileWorkAddressDto";
import { SetEditHomeAddressDto } from "src/app/dto/SetEditHomeAddressDto";
import { SetEditWorkAddressDto } from "src/app/dto/SetEditWorkAddressDto";
import { GetViewProfileHomeAddressDto } from "src/app/dto/GetViewProfileHomeAddressDto";
import { MemberShipDetails } from "src/app/dto/MemberShipDetails";
import { UpgradeToPremiumDto } from "src/app/dto/UpgradeToPremiumDto";
import { TokenValidationResultDto } from "src/app/dto/TokenValidationResultDto";
import { SignalStateService } from "./StateService";
import { APP_CONSTANTS } from "../contants/app.constants";
import { UpdatedRefreshToken } from "src/app/UpdatedRefreshToken";
import { EmptyImage } from "src/app/dto/EmptyImage";
import { ImageLockRequest } from "src/app/dto/ImageLockRequest";
import { LambdaService } from "./LambdaService";

@Injectable({
  providedIn: 'root'
})
export class Commonservice {
  connect = "";
  env = environment.production;
  userLoggedIn: string;
  tokeyKey = 'token';
  token: string;
  private etagBookMark: string | null = null;

  public newSubscribedObject = new Subject<any>();
  $sendMessage = new BehaviorSubject<SendMessageGeneric>(JSON.parse(sessionStorage.getItem('SendMessage')));

  SetSendMessage(smg: SendMessageGeneric) {
    sessionStorage.setItem("SendMessage", JSON.stringify(smg));
    this.$sendMessage.next(smg);
  }

  GetSendMessage() {
    let observable = this.$sendMessage.asObservable();
    let profileNull: boolean = false;
    observable.subscribe(x => {
      if (x === null) {
        profileNull = true;
      }
    });

    if (profileNull) {
      this.SetSendMessage((JSON.parse(sessionStorage.getItem('SendMessage')) as SendMessageGeneric));
      observable = this.$sendMessage.asObservable();
    }

    return observable;
  }

  router: Router;
  constructor(_router: Router, private httpClient: HttpClient, private datePipe: DatePipe, private signalStateService: SignalStateService, private lambdaService: LambdaService) {
    this.router = _router;
    this.connect = this.lambdaService.getLambdaUrl('internalApiCalls');
  }
  getHeightFromLocal() {
    const theresult = this.httpClient.get('assets/jsonvault/height.json');
    return theresult;
  }
  getHeightToLocal() {
    const theresult = this.httpClient.get('assets/jsonvault/height.json');
    return theresult;
  }

  getHeight() {
    const theresult = this.httpClient.get<Array<Dropdown2>>('assets/jsonvault/height.json');
    return theresult;
  }

  getNativePlaceCandidate() {
    const theresult = this.httpClient.get('assets/jsonvault/native.json');
    return theresult;
  }

  getNative() {
    const theresult = this.httpClient.get('assets/jsonvault/native.json');
    return theresult;
  }

  getEmploymentCategory() {
    const theresult = this.httpClient.get('assets/jsonvault/employmentcategory.json');
    return theresult;
  }
  getPartnerEmploymentCategory() {
    const theresult = this.httpClient.get('assets/jsonvault/employmentcategory.json');
    return theresult;
  }

  getNativePlaceFather() {
    const theresult = this.httpClient.get('assets/jsonvault/native.json');
    return theresult;
  }

  getAge() {
    const theresult = this.httpClient.get<Array<Dropdown2>>('assets/jsonvault/age.json');
    return theresult;
  }


  getFreeEntries() {
    const theresult = this.httpClient.get<Array<Dropdown2>>('https://jsonplaceholder.typicode.com/posts');
    return theresult;
  }

  getCountry() {
    const theresult = this.httpClient.get<Array<Dropdown>>(this.connect + '/OpenApi/GetCountries')
    return theresult;
  }

  getStatesBasedOnCountry(countryId: string) {
    const theresult = this.httpClient.get<Array<Dropdown>>(this.connect + '/Register/GetStatesBasedOnCountry/' + countryId)
    return theresult;
  }

  getCitiesBasedOnState(stateId: string, countryId: string) {
    const theresult = this.httpClient.get<Array<Dropdown>>(this.connect + '/Register/GetCitiesBasedOnState/' + stateId + '/' + countryId)
    return theresult;
  }

  getCountryTypedropdown() {
    const theresult = this.httpClient.get<Array<Dropdown>>(this.connect + '/OpenApi/GetCountries')
    return theresult;
  }

  getStatesBasedOnCountryTypedropdown(countryId: string) {
    const theresult = this.httpClient.get<Array<Dropdown>>(this.connect + '/Register/GetStatesBasedOnCountry/' + countryId)
    return theresult;
  }

  getCitiesBasedOnStateTypedropdown(stateId: string, countryId: string) {
    const theresult = this.httpClient.get<Array<Dropdown>>(this.connect + '/Register/GetCitiesBasedOnState/' + stateId + '/' + countryId)
    return theresult;
  }

  getMaritalStatus() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/maritalstatus.json');
    return theresult;
  }

  getParentsSettledAt() {
    const theresult = this.httpClient.get('assets/jsonvault/parentssettled.json');
    return theresult;
  }

  getPhysicalStatus() {
    const theresult = this.httpClient.get('assets/jsonvault/physicalstatus.json');
    return theresult;
  }

  getFathersPlace() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/country.json');
    return theresult;
  }

  getWorkPlace() {
    const theresult = this.httpClient.get('assets/jsonvault/country.json');
    return theresult;
  }

  getReligion() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/partnerreligiondenominationconsidered.json');
    return theresult;
  }

  getQualification() {
    const theresult = this.httpClient.get('assets/jsonvault/educationalcategory.json');
    return theresult;
  }

  getJobCategory() {
    const theresult = this.httpClient.get('assets/jsonvault/jobcategory.json');
    return theresult;
  }

  getComplexion() {
    const theresult = this.httpClient.get('assets/jsonvault/candidateComplexion.json');
    return theresult;
  }

  getLookingFor() {
    const theresult = this.httpClient.get('assets/jsonvault/lookingFor.json');
    return theresult;
  }

  getGender() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/gender.json');
    return theresult;
  }

  getEducationCategory() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/educationalcategory.json');
    return theresult;
  }

  getPartnerEducationCategory() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/educationalcategory.json');
    return theresult;
  }

  getSecurityQuestions() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/securityquestion.json');
    return theresult;
  }

  getDiocese() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/diocese.json');
    return theresult;
  }

  getPartnerDiocese() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/diocese.json');
    return theresult;
  }

  getSortBy() {
    const theresult = this.httpClient.get('assets/jsonvault/sortBy.json');
    return theresult;
  }

  getBloodGroup() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/candidateBloodgroup.json');
    return theresult;
  }

  getPartnerReligion() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/partnerreligiondenominationconsidered.json');
    return theresult;
  }

  isPhoneNumberRegistered(phoneNumber: string) {
    const theresult = this.httpClient.get<boolean>(this.connect + '/Register/IsPhoneNumberRegistered/' + phoneNumber);
    return theresult;
  }

  IsEmailRegistered(email: string) {
    const theresult = this.httpClient.get<boolean>(this.connect + '/Register/IsEmailRegistered/' + email);
    return theresult;
  }

  getStatesRelatedToCountries() {
    const theresult = this.httpClient.get<Array<dropdown>>('assets/jsonvault/states.json');
    return theresult;
  }

  getuserLoggedIn() {
    let userLoggedIn = "";
    return userLoggedIn;
  }

  changePassword(userModel) {
    const self = this;
    let theresult: any;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    theresult = this.httpClient.post(self.connect + '/ResetPassword/ResetUserPassword', userModel, httpOptions);

    return theresult;
  }

  AddBookMark(userModel: AddBookmarkModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.post<SaveResponseModel>(self.connect + '/Bookmark/AddBookMark', userModel, httpOptions).pipe(
      tap(() => {
        // Invalidate the ETag when a bookmark is Added
        this.etagBookMark = null;
      })
    );
    return theresult;
  }

  removeBookMark(userModel: RemoveBookmarkModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.get<DeleteResponseModel>(self.connect + '/Bookmark/DeleteMessageFromBookMark/' + userModel.bookmarkId, httpOptions).pipe(
      tap(() => {
        // Invalidate the ETag when a bookmark is removed
        this.etagBookMark = null;
      }));
    return theresult;
  }

  sendMessage(userModel: SendMessageModelCsKind) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.post<SaveResponseModel>(self.connect + '/api/MessageInbox/SendMessage', userModel, httpOptions);
    return theresult;
  }

  sendExpressInterest(userModel: ExpressInterestSendModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.post<SaveResponseModel>(self.connect + '/api/ExpressedInterestSent/SendExpressInterest', userModel, httpOptions);
    return theresult;
  }

  sendAcceptMyMesssage(urm: ResponseFromUserModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.post<UpdateResponseModel>(self.connect + '/api/MessageInbox/AcceptMyMesssageInbox', urm, httpOptions);
    return theresult;
  }

  sendAcceptExpressInterest(urm: ResponseFromUserModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.post<UpdateResponseModel>(self.connect + '/api/ExpressInterestRecieved/AcceptExpressInterest', urm, httpOptions);
    return theresult;
  }


  // message inbox
  getMessageFromInbox(userloggedIn) {
    const self = this;
    const theresult = self.httpClient.get<MessageInboxModel>(self.connect + '/api/MessageInbox/GetInboxMessages/' + userloggedIn);
    return theresult;
  }

  getListOfCountries() {
    const self = this;
    const theresult = self.httpClient.get(self.connect + '/api/MessageInbox/GetInboxMessages');
    return theresult;
  }

  deleteSelectedMessages(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessageInbox/DeleteMessageFromInbox',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteMultipleSelectedMessages(idsToDelete: MessagesToDelete) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessageInbox/DeleteMultipleItemsFromInbox',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteMultipleItemsFromMyMessageSent(idsToDelete: MessagesToDelete) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessageFromOutBox/DeleteMultipleItemsFromSent',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteFromOutBox(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessageFromOutBox/DeleteMessageFromOutBox',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  responseMessageFromUser(responseFromUserModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post(
        self.connect + '/api/MessageRepliesRecieved/SaveMessageInterestRecieved',
        responseFromUserModel,
        httpOptions
      );
    return theresult;
  }
  // message inbox

  // message recieved
  getMessageRecieved(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get(
        self.connect +
        '/api/MessageRepliesRecieved/GetMessageRepliesRecieved/' +
        userloggedIn
      );
    return theresult;
  }


  //set message to read state
  setToRead(id) {
    const self = this;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const theresult = this.httpClient
      .post(
        self.connect + '/api/MessageInbox/MakeMessageStateRead',
        id,
        httpOptions
      );
    return theresult;
  }



  getMessageSent(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get(
        self.connect +
        '/api/MessageFromOutBox/MessageOutboxDataSet/' +
        userloggedIn
      );
    return theresult;
  }

  getMessageSendTrash(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get(
        self.connect +
        '/api/MessagesOutBoxTrash/MyMessageSendTrash/' +
        userloggedIn
      );
    return theresult;
  }

  getMessageReceivedTrash(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get(
        self.connect +
        '/api/MessageReceviedTrash/MyMessageReceviedTrash/' +
        userloggedIn
      );
    return theresult;
  }

  getExpressedInterestRecieved(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get(
        self.connect +
        '/api/ExpressedInterestReceived/ExpressedInterestRecievedDataSet/' +
        userloggedIn
      );
    return theresult;
  }

  getExpressedInterestSent(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get(
        self.connect +
        '/api/ExpressedInterestSent/ExpressedInterestSentDataSet/' +
        userloggedIn
      );
    return theresult;
  }

  ExpressedInterestReceivedTrash(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get(
        self.connect +
        '/api/ExpressedInterestReceivedTrash/ExpressedInterestReceivedTrash/' +
        userloggedIn
      );
    return theresult;
  }

  ExpressedInterestSendTrash(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get(
        self.connect +
        '/api/ExpressedInterestSendTrash/ExpressedInterestSendTrash/' +
        userloggedIn
      );
    return theresult;
  }

  getUserInformationForHomePage(userloggedIn) {
    const self = this;
    const theresult = this.httpClient
      .get<HomePageUserInfoModel>(
        self.connect +
        '/HomePage/GetHomePageUserInformation/' +
        userloggedIn
      );
    return theresult;
  }

  getLargeImage(imageId) {
    const self = this;
    const theresult = this.httpClient
      .get<ImageCatalogueModel>(
        self.connect +
        '/api/StoreImage/GetLargerImage/' + imageId
      );
    return theresult;
  }

  getLargeImageEx(imageId): Observable<Array<LargerImageWithName>> {
    const self = this;
    const theresult = this.httpClient
      .get<Array<LargerImageWithName>>(
        self.connect +
        '/api/StoreImage/GetLargerImagesForVault/' + imageId
      );
    return theresult;
  }

  getLargeMainImage(imageId) {
    const self = this;
    const theresult = this.httpClient
      .get<ImageCatalogueModel>(
        self.connect +
        '/api/StoreImage/GetLargeMainImage/' + imageId
      );
    return theresult;
  }

  deleteRecievedSelectedMessages(idsToDelete) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post(
        self.connect + '/api/StrongFort/DeleteReievedMessageFromInbox',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }



  // hide profile section
  getVisisbilityStatus(id) {
    const self = this;
    const theresult = this.httpClient
      .get<string>(
        self.connect +
        '/api/CandidateProfileVisible/getVisisbilityStatus/' + id
      );
    return theresult;
  }

  setVisisbilityStatus(visibilityStatus) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponseModel>(
        self.connect + '/api/CandidateProfileVisible/setVisisbilityStatus',
        visibilityStatus,
        httpOptions
      );
    return theresult;
  }



  searchBasedOnId(id: string) {
    const self = this;
    const theresult = this.httpClient
      .get<Array<SearchResultByIdModel>>(
        self.connect + '/Register/SearchBasedOnId/' + id
      );
    return theresult;
  }



  saveNewUser(savenewuser) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<NewlyCreatedUserResponseModel>(
        self.connect + '/api/StrongFort/RegisterUser',
        savenewuser,
        httpOptions
      );
    return theresult;
  }

  // quick search


  edituserInfo(registeredUser) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/Register/UpateRegisteredUser',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  setBasicUserInfo(registeredUser: BasicRegisterUserModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditProfileBasic',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  setEditJobDetails(registeredUser: GetEditProfileJobDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditJobDetails',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  setEditContactDetails(registeredUser: GetEditProfileContactDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditContactDetails',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  setEditFamilyDetails(registeredUser: GetEditProfileFamilyDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditFamilyDetails',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  setEditProfilePartner(registeredUser: GetEditProfilePartnerMegaDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditProfilePartner',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  setEditProfileReferences(registeredUser: SetEditProfileReferenceDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditProfileReferences',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  setEditProfileOther(registeredUser: SetEditProfileOtherDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditProfileOther',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  quickSearch(QuickSearchParam) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<QuickSearchResult>(
        self.connect + '/api/QuickSearch/QuickSearch',
        QuickSearchParam,
        httpOptions
      );
    return theresult;
  }

  SaveSearch(QuickSearchParam) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponseModel>(
        self.connect + '/api/QuickSearch/SaveSearch',
        QuickSearchParam,
        httpOptions
      );
    return theresult;
  }

  SaveSearchAdvancedSearch(searchQueryBody: SearchQueryBody) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponseModel>(
        self.connect + '/api/AdvancedSearch/SaveSearchAdvancedSearch',
        searchQueryBody,
        httpOptions
      );
    return theresult;
  }

  // advanced search
  advancedSearch(advancedSearchParam) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post(
        self.connect + '/api/AdvancedSearch/AdvancedSearch',
        advancedSearchParam,
        httpOptions
      );
    return theresult;
  }
  // advanced search

  // Login Page

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }

  login(user: superUserMine) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const uuid = this.guid();
    user.ID = uuid;
    const jsonised = JSON.stringify(user);
    const self = this;
    const theresult = this.httpClient
      .post(self.connect + '/OpenApi/AuthenticateUser', jsonised, httpOptions);
    return theresult;
  }

  checkLogin(): boolean {
    const token = JSON.parse(this.signalStateService.getValue(this.tokeyKey)());
    return token != null;
  }

  getLocalToken(): string {
    if (this.signalStateService.getValue(APP_CONSTANTS.token)() != null) {
      this.token = JSON.parse(this.signalStateService.getValue(APP_CONSTANTS.token)());
    }
    return this.token;
  }

  getLocalRefresherToken(): string {
    if (this.signalStateService.getValue(APP_CONSTANTS.refresherToken)() != null) {
      this.token = JSON.parse(this.signalStateService.getValue(APP_CONSTANTS.refresherToken)());
    }
    return this.token;
  }

  initAuthHeaders(): Headers {
    const self = this;
    const token = self.getLocalToken();
    if (token == null) {
      throw Error;
    }

    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + token);

    return headers;
  }

  handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
  // Login Page


  // book mark page
  getBookmarks(userloggedIn) {
    const self = this;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    let headers = new HttpHeaders();

    let connect = this.lambdaService.getLambdaUrl('internalApiCalls');
    let apiEndPint = "";
    if (this.etagBookMark) {
      httpOptions.headers = headers.set('If-None-Match', this.etagBookMark);
    }

    apiEndPint = '/Bookmark/getBookmarkedPeople/';

    const theresult = this.httpClient.get<any>(connect + apiEndPint + userloggedIn, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        // If a new ETag is returned, store it
        if (response.headers.has('ETag')) {
          this.etagBookMark = response.headers.get('ETag');
        }
        return response.body;
      })
    );
    //  this.httpClient
    //   .get(connect + apiEndPint + userloggedIn, httpOptions);
    return theresult;
  }
  // book maark page

  //GET USER INFORMATION
  getUserInfo(username): Observable<RegisteredUser> {
    const theresult = this.httpClient.get<RegisteredUser>(this.connect + '/Register/GetUserInfo/' + username);
    return theresult;
  }

  getUserVault(username): Observable<UserVaultModel> {
    const theresult = this.httpClient.get<UserVaultModel>(this.connect + '/api/Vault/GetUserVault/' + username);
    return theresult;
  }

  getUserImageCollection(username): Observable<UserImageList> {
    const theresult = this.httpClient.get<UserImageList>(this.connect + '/api/Vault/GetUserImageCollection/' + username);
    return theresult;
  }


  GetEditPageListObjects(username): Observable<EditPageMegaBucket> {
    const theresult = this.httpClient.get<EditPageMegaBucket>(this.connect + '/HomePage/GetEditPageObjects/' + username);
    return theresult;
  }

  getRefresherToken(username, existingRefreshToken): Observable<UpdatedRefreshToken> {
    const theresult = this.httpClient.get<UpdatedRefreshToken>(this.connect + '/OpenApi/GetRefreshToken/' + username + "/" + existingRefreshToken);
    return theresult;
  }

  setUserInfo(userInfo: RegisteredUser) {
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  //GET USER INFORMATION

  addCrib(data) {
    this.newSubscribedObject.next(data);
  }


  expressInterest(eic: ExpressInterestCarrier): ExpressInterestModel {
    let mtd = new ExpressInterestModel();
    mtd.show = true;
    mtd.header = "SEND EXPRESS INTEREST TO " + eic.userToName?.toUpperCase();
    mtd.modelContent = "I am interested in your profile. Please 'Accept ' if you are interested";
    mtd.showHeader = true;
    mtd.showFooter = true;
    mtd.FromUser = eic.fromId;
    mtd.ToUser = eic.userToId;
    mtd.Accept = true;
    mtd.modelFooter = "DATE : " + eic.currentDate;
    mtd.sendUserActualName = eic.userToName?.toUpperCase();
    return mtd;
  }

  sendMessageModel(mtd: SendMessageModel): SendMessageModel {
    mtd.show = true;
    mtd.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    mtd.header = "SEND MESSAGE TO " + mtd.userToName;
    mtd.modelContent = "";
    mtd.showHeader = true;
    mtd.showFooter = true;
    mtd.modelFooter = "DATE : " + mtd.currentDate;
    return mtd;
  }


  deleteExpressInterestReceived(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/ExpressedInterestReceived/DelExpressedInterestReceived',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  //Delete section
  deleteMessageFromExpressReceived(deleteId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/ExpressedInterestReceived/DelExpressedInterestReceived',
        deleteId,
        httpOptions
      );
    return theresult;
  }

  deleteMultipleMessageFromExpressReceived(deleteId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/ExpressedInterestReceived/DelMultipleExpressedInterestReceived',
        deleteId,
        httpOptions
      );
    return theresult;
  }

  deleteRecordExpressSent(deleteId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/ExpressedInterestSent/DeleteExpressedInterestSent',
        deleteId,
        httpOptions
      );
    return theresult;
  }

  deleteMultipleRecordExpressSent(deleteId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/ExpressedInterestSent/DeleteMultipleExpressedInterestSent',
        deleteId,
        httpOptions
      );
    return theresult;
  }


  deleteRecordExpressTrash(deleteId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/ExpressedInterestTrash/DeleteExpressedInterestTrash',
        deleteId,
        httpOptions
      );
    return theresult;
  }



  // =====================Delete Trash Records====================================//
  deleteTrashMessageReceived(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessageReceviedTrash/deleteTrashMessageReceived',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteTrashMessageSend(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessagesOutBoxTrash/deleteTrashMessageSent',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteMultipleItemsFromSentTrash(idsToDelete: MessagesToDelete) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessagesOutBoxTrash/DeleteMultipleItemsFromSentTrash',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteTrashMessageReceivedMultiple(idsToDelete: MessagesToDelete) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessageReceviedTrash/DeleteTrashMessageReceivedMultiple',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteExpressInterestSentTrash(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/ExpressedInterestSendTrash/deleteExpressInterestSentTrash',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteMultipleExpressInterestSentTrash(idsToDelete) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/ExpressedInterestSendTrash/DeleteMultipleExpressInterestSentTrash',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  deleteExpressInterestReceivedTrash(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessageReceviedTrash/deleteExpressInterestReceivedTrash',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }
  // =====================Delete Trash Records====================================//


  // ------------------RESTORE SECTION--------------//

  restoreTrashMessageReceived(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessageReceviedTrash/restoreTrashMessageReceived',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  restoreTrashMessageSend(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessagesTrash/restoreTrashMessageSent',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  restoreExpressInterestSent(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessagesTrash/restoreExpressInterestSent',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  restoreExpressInterestReceived(idsToDelete: DeleteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<DeleteResponseModel>(
        self.connect + '/api/MessagesTrash/restoreExpressInterestReceived',
        idsToDelete,
        httpOptions
      );
    return theresult;
  }

  // ------------------RESTORE SECTION--------------//
  savevaultinfo(svm: UserVaultModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponseModel>(
        self.connect + '/api/Vault/SaveUserVault',
        svm,
        httpOptions
      );
    return theresult;
  }
  // ------------------RESTORE SECTION--------------//

  // ------------------RESTORE SECTION--------------//
  registerNewMember(rnu: RegisterNewMemberModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponseModel>(
        self.connect + '/OpenApi/RegisterNewMember',
        rnu,
        httpOptions
      );
    return theresult;
  }
  // ------------------RESTORE SECTION--------------//

  // searchStartAge(strArray: Dropdown2[], text):Dropdown2[]{
  //   text = text.toLowerCase();
  //   let retulstantArray =  strArray.filter(x=>x.name.toLowerCase().includes(text));
  //   return retulstantArray;
  // }

  // searchEndAge(strArray: Dropdown2[], text):Dropdown2[]{
  //   text = text.toLowerCase();
  //   let retulstantArray =  strArray.filter(x=>x.name.toLowerCase().includes(text));
  //   return retulstantArray;
  // }

  searchArrayDropDrown2(strArray: Dropdown2[], text): Dropdown2[] {
    text = text.toLowerCase();
    let retulstantArray = strArray.filter(x => x.Name.toLowerCase().includes(text));
    return retulstantArray;
  }

  QuickSearchHomePage(qrm: QuickSearchHomePageParam) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<QuickSearchResult>(
        self.connect + '/OpenApi/QuickSearchHomePage',
        qrm,
        httpOptions
      );
    return theresult;
  }



  // ------------------IMAGE VAULT SECTION--------------//
  // getVisisbilityStatus(id) {
  //   const self = this;
  //   const theresult = this.httpClient
  //     .get<string>(
  //       self.connect +
  //       '/api/StrongFort/getVisisbilityStatus?id=' + id
  //     );
  //   return theresult;
  // }

  // setVisisbilityStatus(visibilityStatus) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*'
  //     })
  //   };
  //   const self = this;
  //   const theresult = this.httpClient
  //     .post<SaveResponseModel>(
  //       self.connect + '/api/StrongFort/setVisisbilityStatus',
  //       visibilityStatus,
  //       httpOptions
  //     );
  //   return theresult;
  // }

  // ------------------IMAGE VAULT SECTION--------------//

  getHomeUserProfile(username) {
    const self = this;
    const theresult = this.httpClient
      .get<MemberShipInformationModel>(
        self.connect +
        '/HomePage/GetHomeUserProfile/' +
        username
      );
    return theresult;
  }

  getUserBookProfileForHomePage(username) {
    const self = this;
    const connect = self.lambdaService.getLambdaUrl('internalApiCalls');
    const theresult = self.httpClient
      .get<Array<ListOfusersBookMarkedMiniModel>>(
        connect +
        '/HomePage/GetHomeBookmarkProfile/' +
        username
      );
    return theresult;
  }

  getViewProfileBasic(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileBasicDto>(
        self.connect +
        '/ViewProfile/GetViewProfileBasic/' +
        username
      );
    return theresult;
  }

  getViewProfileJobInformation(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileJobInformationDto>(
        self.connect +
        '/ViewProfile/GetViewProfileJobInformation/' +
        username
      );
    return theresult;
  }

  getViewProfileFamilyDetails(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileFamilyDetailsDto>(
        self.connect +
        '/ViewProfile/GetViewProfileFamilyDetails/' +
        username
      );
    return theresult;
  }

  getViewProfilePost(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfilePostDto>(
        self.connect +
        '/ViewProfile/GetViewProfilePost/' +
        username
      );
    return theresult;
  }

  getViewProfileHomeAddress(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileHomeAddressDto>(
        self.connect +
        '/ViewProfile/GetViewProfileHomeAddress/' +
        username
      );
    return theresult;
  }

  getViewProfileWorkAddress(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileWorkAddressDto>(
        self.connect +
        '/ViewProfile/GetViewProfileWorkAddress/' +
        username
      );
    return theresult;
  }

  setViewProfileHomeAddress(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileHomeAddressDto>(
        self.connect +
        '/ViewProfile/SetViewProfileHomeAddress/' +
        username
      );
    return theresult;
  }

  setViewProfileWorkAddress(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileWorkAddressDto>(
        self.connect +
        '/ViewProfile/SetViewProfileWorkAddress/' +
        username
      );
    return theresult;
  }

  getViewProfilePicture(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfilePictureDto>(
        self.connect +
        '/ViewProfile/GetViewProfilePicture/' +
        username
      );
    return theresult;
  }

  getViewProfileOther(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileOtherDto>(
        self.connect +
        '/ViewProfile/GetViewProfileOther/' +
        username
      );
    return theresult;
  }


  getViewProfilePartnerPreferences(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfilePartnerPreferencesDto>(
        self.connect +
        '/ViewProfile/GetViewProfilePartnerPreferences/' +
        username
      );
    return theresult;
  }

  getViewProfileReferencesInKerala(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetViewProfileReferencesInKeralaDto>(
        self.connect +
        '/ViewProfile/GetViewProfileReferencesInKerala/' +
        username
      );
    return theresult;
  }

  getSavedSearches(username) {
    const self = this;
    const theresult = this.httpClient
      .get<Array<UserSavedSearchResult>>(
        self.connect +
        '/CandidateSqlQuerySaved/GetSavedSearches/' +
        username
      );
    return theresult;
  }

  getMembersWhoViewedMyContactDetails(username) {
    const self = this;
    const theresult = this.httpClient
      .get<Array<MembersWhoViewedMyContactDetailsModel>>(
        self.connect +
        '/VisitCatalogue/GetMemberWhoViewedMyContactInfo/' +
        username
      );
    return theresult;
  }

  getContactDetailViewedByYou(username) {
    const self = this;
    const connect = this.lambdaService.getLambdaUrl('internalApiCalls');
    const theresult = this.httpClient
      .get<Array<ContactDetailViewedByMeModel>>(
        connect +
        '/VisitCatalogue/GetContactDetailViewedByYou/' +
        username
      );
    return theresult;
  }

  getRecentVisitorsToYourProfile(username) {
    const self = this;
    const connect = this.lambdaService.getLambdaUrl('internalApiCalls');
    const theresult = this.httpClient
      .get<Array<RecentVisitorsToMyProfileModel>>(
        connect + '/VisitCatalogue/GetRecentVisitorsToYourProfile/' +
        username
      );
    return theresult;
  }

  getLoggedInUsersRecentVisits(username) {
    const self = this;
    const connect = this.lambdaService.getLambdaUrl('internalApiCalls');
    const theresult = this.httpClient
      .get<Array<YourRecentVisitsModelOVC>>(
        connect +
        '/VisitCatalogue/GetLoggedInUsersRecentVisits/' +
        username
      );
    return theresult;
  }

  getEditProfileBasic(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetEditProfileBasicDto>(
        self.connect +
        '/EditProfile/GetEditProfileBasic/' +
        username
      );
    return theresult;
  }

  getEditProfileContact(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetEditProfileContactDto>(
        self.connect +
        '/EditProfile/GetEditProfileContact/' +
        username
      );
    return theresult;
  }

  getEditProfileFamily(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetEditProfileFamilyDto>(
        self.connect +
        '/EditProfile/GetEditProfileFamily/' +
        username
      );
    return theresult;
  }

  getEditProfileJob(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetEditProfileJobDto>(
        self.connect +
        '/EditProfile/GetEditProfileJob/' +
        username
      );
    return theresult;
  }

  getEditProfilePartner(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetEditProfilePartnerMegaDto>(
        self.connect +
        '/EditProfile/GetEditProfilePartner/' +
        username
      );
    return theresult;
  }

  getEditProfileReferences(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetEditProfileReferenceDto>(
        self.connect +
        '/EditProfile/GetEditProfileReferences/' +
        username
      );
    return theresult;
  }

  getEditProfileOther(username) {
    const self = this;
    const theresult = this.httpClient
      .get<GetEditProfileOtherDto>(
        self.connect +
        '/EditProfile/GetEditProfileOther/' +
        username
      );
    return theresult;
  }

  recordContactDetailsViewedByOwner(recordContactDetailsModel) {
    const self = this;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    let theResult = this.httpClient.post(self.connect + '/VisitCatalogue/RecordContactDetailsViewedByOwner', recordContactDetailsModel, httpOptions);
    theResult.subscribe(data => {
    });
  }


  getOtherUserProfilePrimary(nameOfTheOneBeingVisited, username) {
    const self = this;
    const theresult = this.httpClient
      .get<ViewOtherProfilePrimaryTab>(
        self.connect + '/HomePage/GetOtherUserProfilePrimary/' + nameOfTheOneBeingVisited + "/" +
        username
      );
    return theresult;
  }

  getOtherProfileReligiosSocialBackGroundTab(nameOfTheOneBeingVisited) {
    const self = this;
    const theresult = this.httpClient
      .get<CandidateReliionBackGround>(
        self.connect + '/HomePage/GetOtherProfileReligiosSocialBackGroundTab/' + nameOfTheOneBeingVisited
      );
    return theresult;
  }

  getOtherProfilePartnerRequirementTab(nameOfTheOneBeingVisited, username) {
    const self = this;
    const theresult = this.httpClient
      .get<ViewOtherProfilePartnerRequirementTab>(
        self.connect + '/HomePage/GetOtherProfilePartnerRequirementTab/' + nameOfTheOneBeingVisited + '/' + username
      );
    return theresult;
  }

  getMemberEducationAndCareerTab(nameOfTheOneBeingVisited) {
    const self = this;
    const theresult = this.httpClient
      .get<ViewOtherEducationAndCareerTab>(
        self.connect + '/HomePage/GetMemberEducationAndCareerTab/' + nameOfTheOneBeingVisited
      );
    return theresult;
  }

  getMemberFamilyDetailsTab(nameOfTheOneBeingVisited, nameOfOneWhovisits) {
    const self = this;
    const theresult = this.httpClient
      .get<MemberFamilyDetailsDto>(
        self.connect + '/HomePage/GetMemberFamilyDetailsTab/' + nameOfTheOneBeingVisited + "/" + nameOfOneWhovisits
      );
    return theresult;
  }

  getMemberContactDetailsTab(nameOfTheOneBeingVisited, nameOfOneWhovisits) {
    const self = this;
    const theresult = this.httpClient
      .get<MemberContactDetailsDto>(
        self.connect + '/HomePage/GetMemberContactDetailsTab/' + nameOfTheOneBeingVisited + '/' + nameOfOneWhovisits
      );
    return theresult;
  }

  getMemberUserBoardInformation(nameOfTheOneBeingVisited,) {
    const self = this;
    const theresult = this.httpClient
      .get<MemberUserBoardDOtherViewto>(
        self.connect + '/HomePage/GetMemberUserBoardInformation/' + nameOfTheOneBeingVisited
      );
    return theresult;
  }

  getOccupations() {
    const self = this;
    const theresult = this.httpClient
      .get<MemberUserBoardDOtherViewto>(
        self.connect + '/OpenApi/GetOccupations'
      );
    return theresult;
  }

  sendContactUsMail(contactUsDto: ContactUsDto) {
    const self = this;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const theresult = this.httpClient
      .post<SaveResponseModel>(
        self.connect + '/ContactUs/SendContactUs',
        contactUsDto,
        httpOptions
      );
    return theresult;
  }

  getUserBeingSearchedInChat() {
    const self = this;
    const theresult = this.httpClient
      .get<Array<ChatUserListResponse>>(
        self.connect + '/Chat/GetUserBeingSearchedInChat'
      );
    return theresult;
  }

  getInitialChatList(username: string) {
    const self = this;
    const theresult = this.httpClient.get<Array<ChatUserListResponse>>(self.connect + '/api/Chat/GetInitialChatList/' + username);
    return theresult;
  }

  getChatUserSearch(username: string, searchText: string) {
    const self = this;
    const theresult = this.httpClient.get<Array<ChatUserListResponse>>(self.connect + '/api/Chat/GetChatUserSearch/' + username + '/' + searchText);
    return theresult;
  }

  setEditHomeAddress(registeredUser: SetEditHomeAddressDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditHomeAddress',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  setEditWorkAddress(registeredUser: SetEditWorkAddressDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/EditProfile/SetEditWorkAddress',
        registeredUser,
        httpOptions
      );
    return theresult;
  }

  getMembershipDetails(userloggedIn) {
    const self = this;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const theresult = this.httpClient
      .get<MemberShipDetails>(
        self.connect +
        '/Membership/GetMembershipDetails/' +
        userloggedIn, httpOptions
      );
    return theresult;
  }

  upgradeToPremium(upgradeToPremiumDto: UpgradeToPremiumDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient
      .post<SaveResponse>(
        self.connect + '/Membership/UpgradeToPremium',
        upgradeToPremiumDto,
        httpOptions
      );
    return theresult;
  }

  validateToken(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const theresult = this.httpClient.get<TokenValidationResultDto>(this.connect + '/OpenApi/ValidateTokenAsync/' + token, httpOptions)
    return theresult;
  }

  deleteContactsVisitedByMember(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.get<DeleteResponseModel>(self.connect + '/VisitCatalogue/DeleteContactsVisitedByMember/' + id, httpOptions);
    return theresult;
  }

  deleteGuestWhoVisitedOwner(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.get<DeleteResponseModel>(self.connect + '/VisitCatalogue/DeleteGuestWhoVisitedOwner/' + id, httpOptions);
    return theresult;
  }

  deleteRecentVisitorsToMyProfile(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.get<DeleteResponseModel>(self.connect + '/VisitCatalogue/DeleteRecentVisitorsToMyProfile/' + id, httpOptions);
    return theresult;
  }

  deleteOwnersVisits(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.get<DeleteResponseModel>(self.connect + '/VisitCatalogue/DeleteOwnersVisits/' + id, httpOptions);
    return theresult;
  }

  deleteSavedSearch(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const self = this;
    const theresult = this.httpClient.get<DeleteResponseModel>(self.connect + '/CandidateSqlQuerySaved/DeleteSavedSearch/' + id, httpOptions);
    return theresult;
  }


  get404Image() {
    return this.httpClient.get<EmptyImage>(this.connect + '/api/Vault/Get404Image');
  }

  UpdateImageLockStatusAsync(searchErrorRequest: ImageLockRequest) {
    const theresult = this.httpClient.post<SaveResponseModel>(`${this.connect}/api/Vault/UpdateImageLockStatus`, searchErrorRequest);
    return theresult;
  }
}
