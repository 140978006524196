import { Component, Input, OnInit } from '@angular/core';
import { RegisteredUser } from '../../../view/view.components.domain';
import { ActivatedRoute } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ViewOtherEducationAndCareerTab } from 'src/app/dto/ViewOtherEducationAndCareerTab';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';

@Component({
  selector: 'candidate-personal-education-information',
  templateUrl: './candidate-personal-education-information.component.html',
  styleUrls: ['./candidate-personal-education-information.component.css']
})
export class CandidatePersonalEducationInformationComponent implements OnInit {
  mc: ViewOtherEducationAndCareerTab = {
    CandidateEducationSubject: '',
    CandidateEducationCategory: '',
    CandidateOccupation: '',
    CandidateOccupationInDetail: '',
    CandidateNameFirm: '',
    CandidateWorkplace: '',
    CandidateAnnualIncome: '',
    CandidateEmploymentCategory: ''
  };
  showLoader = true;
  userloggedIn: string;
  userWhosInformationIsRequired: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute, private signalStateService: SignalStateService) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;

    // this._Activatedroute.params.subscribe(params => {
    //   this.userWhosInformationIsRequired = params['id'];
    //   self.getPrimaryUserInformation();
    // });

    
    let aboutUser = this.signalStateService.getValue(APP_CONSTANTS.UserWhoProfileIsBeingVisited)();
    this.userWhosInformationIsRequired  = aboutUser.replace(/"/g, '');
    self.getPrimaryUserInformation();
  }
  getPrimaryUserInformation() {
    const self = this;

    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getMemberEducationAndCareerTab(self.userWhosInformationIsRequired).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }


}
