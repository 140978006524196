import { Component, OnInit } from '@angular/core';
import { MenuModel } from "src/app/dto/MenuModel";
import { SignalStateService } from '../../reusable/servicecenter/StateService';
import { APP_CONSTANTS } from '../../reusable/contants/app.constants';

@Component({
  selector: 'publictopmenu',
  templateUrl: './publictopmenu.component.html',
  styleUrls: ['./publictopmenu.component.css']
})
export class PublictopmenuComponent implements OnInit {
  menuArray: Array<MenuModel>;
  currentDeviceState: string = "";
  constructor(private signalStateService: SignalStateService) { }

  ngOnInit() {
    const self = this;
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();

    console.log("currentDeviceState", this.currentDeviceState);

    self.menuArray = [];
    const home: MenuModel = {} as MenuModel;
    home.Name = "Home";
    home.Path = "/welcome";
    self.menuArray.push(home);

    const Search: MenuModel = {} as MenuModel;
    Search.Name = "Search";
    Search.Path = "/guestsearch";
    self.menuArray.push(Search);

    const Register: MenuModel = {} as MenuModel;
    Register.Name = "Register";
    Register.Path = "/register";
    self.menuArray.push(Register);


    const paymentOPtions: MenuModel = {} as MenuModel;
    paymentOPtions.Name = "Payment Options";
    paymentOPtions.Path = "/paymentoptions";
    self.menuArray.push(paymentOPtions);


    const aboutus: MenuModel = {} as MenuModel;
    aboutus.Name = "About Us";
    aboutus.Path = "/aboutus";
    self.menuArray.push(aboutus);

    const contactus: MenuModel = {} as MenuModel;
    contactus.Name = "Contact Us";
    contactus.Path = "/contactus";
    self.menuArray.push(contactus);
  }

}
