import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'menu-search',
  templateUrl: './searchinmenu.component.html',
  styleUrls: ['./searchinmenu.component.css']
})
export class SearchinmenuComponent {
  profileId: string;
  constructor(private router: Router) {

  }
  redirectosearchbyprofile() {
    const self = this;
    if (self.profileId?.trim().length > 0) {
      this.router.navigate(['/searchbyid', self.profileId]);
    }
    else {

    }
  }
}
