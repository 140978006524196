import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { MemberFamilyDetailsDto } from 'src/app/private/profile/view/MemberFamilyDetailsDto';

@Component({
  selector: 'other-user-family-details',
  templateUrl: './other-user-family-details.component.html',
  styleUrl: './other-user-family-details.component.css'
})
export class OtherUserFamilyDetailsComponent {
 mc: MemberFamilyDetailsDto = {
    FatherName: '',
    FatherHouseName: '',
    FathersNativePlace: '',
    FatherOccupation: '',
    MotherName: '',
    MotherHouseName: '',
    MotherNativeDistrict: '',
    MotherOccupation: '',
    BrotherMarried: '',
    BrothersUnmarried: '',
    BrotherPriest: '',
    SistersMarried: '',
    SistersUnmarried: '',
    SistersNun: '',
    AbroadCandidateavailabilityatNativeTown: '',
    CandidateAssets: '',
    CandidateBackGround: '',
    CandidateAboutFamily: '',
    ProfileSubmittedBy: ''
  };
  showLoader = true;
  userloggedIn: string;
  userWhosInformationIsRequired: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute, private signalStateService: SignalStateService) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;

    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getPrimaryUserInformation();
    });
  }
  getPrimaryUserInformation() {
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getMemberFamilyDetailsTab(self.userWhosInformationIsRequired, self.userloggedIn).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }

}
