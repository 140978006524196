<div class="barelist">
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4 text-right">
              <img [src]="mc.CandidateProfileImageRealOwner"
                class="profile-image" id="ctl00_ContentPlaceHolder1_pic1View" />
            </div>
            <div class="col-md-4 text-center">
              <img src="../../../../../../assets/icons/match.svg" alt="" style="width: 106px" />
            </div>
            <div class="col-md-4">
              <img [src]="mc.CandidateProfileImageReal"
                class="profile-image" id="ctl00_ContentPlaceHolder2_pic1View" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Age Preferred</div>
        <div class="col-md-9">
          {{ mc.PartnerAgeExpectedFrom }} to {{ mc.PartnerAgeExpectedTo }}
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Height Preferred</div>
        <div class="col-md-9">
          {{ mc.PartnerHeightExpectedFrom }} to {{ mc.PartnerHeightExpectedTo }}
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Denomination Preferred</div>
        <div class="col-md-9">{{ mc.PartnerDenominationConsidered }}</div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Qualification Preferred</div>
        <div class="col-md-9">
          {{ mc.ParnterQualificationPreferred }}
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Occupation Preferred</div>
        <div class="col-md-9">
          {{ mc.PartnerOccupationPreffered }}
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Required Marital Status</div>
        <div class="col-md-9">
          {{ mc.PartnerRequiredMaritalStatus }}
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Required Physical Status</div>
        <div class="col-md-9">{{ mc.PartnerPhysicalStatus }}</div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Required Family Status</div>
        <div class="col-md-9">{{ mc.PartnerFamilyStatus }}</div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">About my Partner</div>
        <div class="col-md-9">{{ mc.PartnerExpectationsbrief }}</div>
      </div>
    </div>
  </div>