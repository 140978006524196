<page>
  <ng-template>
    <p-card legend="PROFILE">
      <other-user-generic-details></other-user-generic-details>
      <p-divider />
      <p-panel header="BASIC">
        <other-user-basic-details></other-user-basic-details>
      </p-panel>
      <p-divider />
      <p-panel header="RELIGION">
        <other-user-religion-details></other-user-religion-details>
      </p-panel>
      <p-divider />
      <p-panel header="PARTNER REQUIREMENTS">
        <other-user-patrner-requirement-details></other-user-patrner-requirement-details>
      </p-panel>
      <p-divider />
      <p-panel header="EDUCATION AND CAREER">
        <other-user-education-and-carerr-details></other-user-education-and-carerr-details>
      </p-panel>
      <p-divider />
      <p-panel header="FAMILY DETAILS">
        <other-user-family-details></other-user-family-details>
      </p-panel>
      <p-divider />
      <p-panel header="CONTACT DETAILS">
        <other-user-contact-details></other-user-contact-details>
      </p-panel>
      <p-divider />
      <other-user-image-details></other-user-image-details>
    </p-card>




  </ng-template>
</page>