<!-- <p-card header="">
  <div class="title-param"> Father's Details</div>

</p-card> -->
<p-fieldset legend="Father's Details">
    <div class="barelist">
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Name
          </div>
          <div class='col-md-9'>
            {{mc.FatherName}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            House Name
          </div>
          <div class='col-md-9'>
            {{mc.FatherHouseName}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Native Place
          </div>
          <div class='col-md-9'>
            {{mc.FathersNativePlace}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Occupation
          </div>
          <div class='col-md-9'>
            {{mc.FatherOccupation}}
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
  
  <br />
  
  <p-fieldset header="" legend="Mother's Details">
    <div class="barelist">
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Name
          </div>
          <div class='col-md-9'>
            {{mc.MotherName}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            House Name
          </div>
          <div class='col-md-9'>
            {{mc.MotherHouseName}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Native Place
          </div>
          <div class='col-md-9'>
            {{mc.MotherNativeDistrict}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Occupation
          </div>
          <div class='col-md-9'>
            {{mc.MotherOccupation}}
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
  <br />
  
  <p-fieldset header="" legend="Sibling's Details">
  
    <div class="barelist">
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            No.of Brothers
          </div>
          <div class='col-md-9'>
            ( {{mc.BrotherMarried}} ) Married ( {{mc.BrothersUnmarried}} ) Unmarried ( {{mc.BrotherPriest}} ) Priest
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            No.of Sisters
  
          </div>
          <div class='col-md-9'>
            ( {{mc.SistersMarried}} ) Married ( {{mc.SistersUnmarried}} ) Unmarried ( {{mc.SistersNun}} ) Nun
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
  
  <br />
  
  <p-fieldset header="" legend="Other Details">
    <div class="barelist">
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Abroad Candidate availability at Native Town
  
          </div>
          <div class='col-md-9'>
            {{mc.AbroadCandidateavailabilityatNativeTown}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Candidate's Share Details
          </div>
          <div class='col-md-9'>
            {{mc.CandidateAssets}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            About the Candidate
          </div>
          <div class='col-md-9'>
  
            {{mc.CandidateBackGround}}
  
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            About Candidate's Family
          </div>
          <div class='col-md-9'>
  
  
            {{mc.CandidateAboutFamily}}
          </div>
        </div>
      </div>
      <div>
        <hr>
        <div class='row'>
          <div class='col-md-3  left-sub-title'>
            Profile Created By
          </div>
          <div class='col-md-9'>
            {{mc.ProfileSubmittedBy}}
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
  <!-- <div class="title-param"> Other Details</div> -->