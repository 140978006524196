import { Component, OnInit, Input } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { RecordContactDetailsModel } from "src/app/dto/RecordContactDetailsModel";
import { ActivatedRoute } from '@angular/router';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { MemberShipInformationModel } from 'src/app/dto/MemberShipInformationModel';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';

@Component({
  selector: 'usertabboard',
  templateUrl: './usertabboard.component.html',
  styleUrls: ['./usertabboard.component.sass']
})
export class UsertabboardComponent implements OnInit {
  activeIndex = 0;
  userWhosInformationIsRequired: string;
  userloggedIn: string;
  constructor(private commonService: Commonservice, private signalStateService: SignalStateService, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;

    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
    });
    let aboutUser = this.signalStateService.getValue(APP_CONSTANTS.UserWhoProfileIsBeingVisited)();
    this.userWhosInformationIsRequired = aboutUser.replace(/"/g, '');


  }
  handleChange(e) {

    if (e.index === 6) {
      const fromCache = this.signalStateService.getValue("BasicProfileDataSet")();
      if (fromCache) {
        let userBasicInfomarmation = JSON.parse(fromCache) as MemberShipInformationModel;
        if (userBasicInfomarmation.membershipType = "Premium") {
          const self = this;
          self.localStore.getCurrentLoggedInUser().then((result) => {
            self.userloggedIn = result.UserName;
            let recordContactDetailsModel = new RecordContactDetailsModel();
            recordContactDetailsModel.ContactViewedOf = this.userWhosInformationIsRequired;
            recordContactDetailsModel.Owner = self.userloggedIn;
            recordContactDetailsModel.VisitedDate = new Date();
            self.commonService.recordContactDetailsViewedByOwner(recordContactDetailsModel);
          })
        }
      }
    }
  }
}
