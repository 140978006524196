import { Component, OnInit } from '@angular/core';
import { ChatUserListResponse } from 'src/app/dto/usersReadyForChat';
import { Commonservice } from '../servicecenter/commonservice.component';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { CommonFunc } from '../servicecenter/CommonFunc';
import { ChatService } from '../servicecenter/ChatService';
import { DynamicComponentService } from '../servicecenter/DynamicComponentService';
import { IndividualChatmodel } from 'src/app/dto/IndividualChatmodel';
import { Chatsignalservice } from '../servicecenter/chatsignalservice';
import { SignalRService } from '../servicecenter/SignalRService';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window-component.component.html',
  styleUrl: './chat-window-component.component.scss'
})
export class ChatWindowComponentComponent implements OnInit {
  users: ChatUserListResponse[] = [];
  isExpanded: boolean = false;
  userloggedIn: string;
  searchText: string;
  masterUserList: ChatUserListResponse[] = [];
  

  constructor(private commonService: Commonservice, private localStore: LocalStore, private commonFunc: CommonFunc, private chatService: ChatService,
    private dynamicComponentService: DynamicComponentService, private chatsignalservice: Chatsignalservice, private signalRService: SignalRService
  ) {

  }

  ngOnInit() {
    const self = this;
    this.signalRService.reinitializeConnection().then(() => console.log('SignalR connection reinitialized'))
    .catch(err => console.error('Error reinitializing SignalR connection: ', err));;
    this.signalRService.getConnectionState().subscribe(state => {
      console.log('SignalR connection state:', state);
      // Update UI or perform actions based on the connection state
    });
    this.signalRService.addMessageListener((response, message) => {
      // debugger
    });
    self.getListOfUsersToDisplayInChat();
  }

  sendMessage() {
    const self = this;
  }

  getListOfUsersToDisplayInChat() {
    const self = this;
    self.users = [];
    self.masterUserList = [];
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.chatsignalservice.fetchChatList(self.userloggedIn).subscribe(data => {
        const sortedData = data.sort((a, b) => {
          if (a.DisplayName < b.DisplayName) {
            return -1;
          }
          if (a.DisplayName > b.DisplayName) {
            return 1;
          }
          return 0;
        });
        self.users.push(...sortedData);
        self.masterUserList.push(...sortedData);
        this.chatService.selectUser(null);
      });
    });
  }

  expandList() {
    this.isExpanded = !this.isExpanded;
  }

  async globalSearch(event: string) {
    const self = this;
    let columnsToFilter = Object.keys(self.masterUserList[0]);
    if (event.trim().length > 0) {
      self.users = self.commonFunc.searchText(self.masterUserList, event, columnsToFilter);
      if (self.users.length === 0) {
        await self.searchUserInDatabase(event);
      }
    }
    else {
      self.users = self.masterUserList;
    }
  }

  async searchUserInDatabase(searchText: string) {
    this.commonService.getChatUserSearch(this.userloggedIn, searchText).subscribe({
      next: (response: any[]) => {
        if (response && response.length > 0) {
          this.users = response;
          this.masterUserList = [...this.masterUserList, ...response];
        }
      },
      error: (error) => {
        console.error('Error searching user in database:', error);
      }
    });
  }

  openDetailedChat(user: ChatUserListResponse) {
    this.chatService.selectUser(user);
    let icm: IndividualChatmodel = {
      AddText: "",
      RecipientDisplayName: user.DisplayName,
      IsExpanded: false,
      Messages: [],
      ShowLoader: false,
      SenderId: this.userloggedIn,
      RecipientId: user.UserID,
      SenderDisplayName: ""
    };
    this.dynamicComponentService.createSenderChatWindow(icm);
  }

  clearSearch() {
    this.searchText = "";
    this.users = [];
    this.users = this.masterUserList;
  }
}
