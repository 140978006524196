import { Component, Input, OnInit } from '@angular/core';
import { RegisteredUser } from '../../../view/view.components.domain';
import { ViewOtherProfilePartnerRequirementTab } from 'src/app/dto/ViewOtherProfilePartnerRequirementTab';
import { ActivatedRoute } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';

@Component({
  selector: 'candidate-personal-partner-information',
  templateUrl: './candidate-personal-partner-information.component.html',
  styleUrls: ['./candidate-personal-partner-information.component.css']
})
export class CandidatePersonalPartnerInformationComponent implements OnInit {
  mc: ViewOtherProfilePartnerRequirementTab = {
    CandidateProfileImageReal: '',
    PartnerAgeExpectedFrom: '',
    PartnerAgeExpectedTo: '',
    PartnerHeightExpectedFrom: '',
    PartnerHeightExpectedTo: '',
    PartnerDenominationConsidered: '',
    PartnerNativePlacePreferred: '',
    ParnterQualificationPreferred: '',
    PartnerRequiredQualificationInDetail: '',
    PartnerOccupationPreffered: '',
    PartnerRequiredOccupationInDetail: '',
    partnerWorkPlaceLookingFor: '',
    PartnerRequiredMaritalStatus: '',
    PartnerPhysicalStatus: '',
    PartnerFamilyStatus: '',
    PartnerExpectationsbrief: '',
    CandidateProfileImageRealOwner: ''
  };
  userWhosInformationIsRequired: string;
  showLoader = true;
  userloggedIn: string;
  imageToDisplay: HostWindowModel;


  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute, private signalStateService: SignalStateService) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;

    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getOtherProfilePartnerRequirementTab();
    });


    let aboutUser = this.signalStateService.getValue(APP_CONSTANTS.UserWhoProfileIsBeingVisited)();
    this.userWhosInformationIsRequired = aboutUser.replace(/"/g, '');
    self.getOtherProfilePartnerRequirementTab();
  }

  getOtherProfilePartnerRequirementTab() {
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;;
      self.imageToDisplay = {} as HostWindowModel;
      self.commonService.getOtherProfilePartnerRequirementTab(self.userWhosInformationIsRequired, self.userloggedIn).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }
}
