import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ViewOtherEducationAndCareerTab } from 'src/app/dto/ViewOtherEducationAndCareerTab';

@Component({
  selector: 'other-user-education-and-carerr-details',
  templateUrl: './other-user-education-and-carerr-details.component.html',
  styleUrl: './other-user-education-and-carerr-details.component.css'
})
export class OtherUserEducationAndCarerrDetailsComponent {
mc: ViewOtherEducationAndCareerTab = {
    CandidateEducationSubject: '',
    CandidateEducationCategory: '',
    CandidateOccupation: '',
    CandidateOccupationInDetail: '',
    CandidateNameFirm: '',
    CandidateWorkplace: '',
    CandidateAnnualIncome: '',
    CandidateEmploymentCategory: ''
  };
  showLoader = true;
  userloggedIn: string;
  userWhosInformationIsRequired: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;

    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getPrimaryUserInformation();
    });

  }
  getPrimaryUserInformation() {
    const self = this;

    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getMemberEducationAndCareerTab(self.userWhosInformationIsRequired).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }

}
