export const apiPath = 'http://localhost:5002';
export const localApiPath = 'http://localhost:5002';
export const secretKey = 'Y4gB@1sE!kLm9%X2uF^zQ$8hW3rT*jN5'; // Replace with a secure key
export const APP_CONSTANTS = {
    BasicProfileDataSet:"BasicProfileDataSet",
    ResolutionData:"ResolutionData",
    HomePageBookMark:"HomePageBookMark",
    HomePageMembersWhoViewedMyProfile:"HomePageMembersWhoViewedMyProfile",
    token:"token",
    refresherToken:"refresherToken",
    userLoggedIn:"userLoggedIn",
    name:"DisplayName",
    loginResponse:"loginResponse",
    HomePageContactsViewedByMe:"HomePageContactsViewedByMe",
    RecentVistorsToMyProfile:"RecentVistorsToMyProfile",
    MyRecentVisits:"MyRecentVisits",
    MySavedSearches:"MySavedSearches",
    QuestionBeforeBookMarkRemoval:"Are you sure that you want to delete the bookmark?",
    QuestionBeforeDeleting:"Are you sure, you want to delete?",
    UserWhoProfileIsBeingVisited:"UserWhoProfileIsBeingVisited",
    BrowserType:"browserType",
  };

