import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostmodelComponent } from 'src/app/common/reusable/model/hostmodel/hostmodel.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ActionResponse } from 'src/app/dto/ActionResponse';
import { AddBookmarkModel } from 'src/app/dto/AddBookmarkModel';
import { ExpressInterestCarrier } from 'src/app/dto/ExpressInterestCarrier';
import { ExpressInterestModel } from 'src/app/dto/ExpressInterestModel';
import { ExpressInterestSendModel } from 'src/app/dto/ExpressInterestSendModel';
import { MemberUserBoardDOtherViewto } from 'src/app/dto/MemberUserBoardDto';
import { SendMessageGeneric } from 'src/app/dto/SendMessageGeneric';


@Component({
  selector: 'other-user-generic-details',
  templateUrl: './other-user-generic-details.component.html',
  styleUrl: './other-user-generic-details.component.css',
  providers: [ConfirmationService, MessageService]
})
export class OtherUserGenericDetailsComponent {
userId: string;
  imageToDisplay: HostWindowModel;
  mc: MemberUserBoardDOtherViewto = {
    candidateProfileImageTmb: "",
    CandidateProfileImageReal: "",
    CandidateUserId: '',
    CandidateName: "",
    ProfileSubmittersRelation: "",
    CandidateLastLogin: undefined,
    Age: "",
    CandidateHeight: "",
    CandidateComplexion: "",
    CandidateDenomination: "",
    CandidateEducationSubject: ""
  }
  showLoader = true;
  userWhosInformationIsRequired: string;
  userloggedIn: string;
  mtd: ExpressInterestModel;
  confirmationTitle: string = "Confirmation";
  expressInterestModel: ExpressInterestSendModel;
  sendMessageText: string;
  userBeingViewed: string;
  smg: SendMessageGeneric;
  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;
  @ViewChild(HostmodelComponent, { static: false }) imageModelWindow: HostmodelComponent;

  constructor(private commonservice: Commonservice, private localStore: LocalStore, 
    private datePipe: DatePipe, private confirmationService: ConfirmationService, private messageService: MessageService,private _activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;

    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.mtd = {} as ExpressInterestModel;

    self._activatedroute.params.subscribe(params => {
      self.userWhosInformationIsRequired = params['id'];
      self.getOtherProfilePartnerRequirementTab();
    });
   
    
  }

  getOtherProfilePartnerRequirementTab() {
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;;
      self.imageToDisplay = {} as HostWindowModel;
      self.commonservice.getMemberUserBoardInformation(self.userWhosInformationIsRequired).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (imageThumbnailId != null) {
      self.commonservice.getLargeImage(imageThumbnailId).subscribe(
        data => {
          self.imageModelWindow.tc.MainImage = data.MainImage;
          self.imageModelWindow.tc.show = true;
          self.imageModelWindow.tc.ThumbNailImages = data.ThumbNails;
          self.imageModelWindow.tc.RouterPath = "/../viewotherprofile";
          self.imageModelWindow.tc.UserId = self.userId;
        },
        error => { }
      );
    }
    else {
      // self.snackBar.open('No Image', "Ok", {
      //   duration: 5000,
      // });
    }
  }


  expressInterestPop(event: Event) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = self.userBeingViewed;
    eic.userToName = self.userBeingViewed.toUpperCase();
    self.mtd = self.commonservice.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  showSendDialogue() {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = self.userBeingViewed;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonservice.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  sendExpressInterest(event: ExpressInterestModel) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonservice.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data?.InsertWorked) {
          self.messageService.add({ severity: data?.InsertWorked ? 'success' : 'error', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          self.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  bookmarkprofile() {
    const self = this;
    let bookmark: AddBookmarkModel = {
      UserWhoIsBookmarking: self.userloggedIn,
      UserWhoIsGettingBookmarked: self.userBeingViewed
    }
    let error: any;
    self.commonservice.AddBookMark(bookmark).subscribe(
      data => {
        if (data?.InsertWorked) {
          self.messageService.add({ severity: data?.InsertWorked ? 'success' : 'error', summary: 'Service Message', detail: data.Message });
        }
        else {
          self.messageService.add({ severity: 'warn', summary: 'Service Message', detail: data.Message });
        }
      },
      error => {
        self.messageService.add({ severity: 'error', summary: 'Service Message', detail: error });
      }
    );
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }

  eventPostSubmit(event: ActionResponse) {
    const self = this;
    self.smg.ShowSendMessage = false;
    if (event.ActionState == true) {
      self.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      self.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }
}
