import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { ViewOtherProfilePrimaryTab } from 'src/app/dto/ViewOtherProfilePrimaryTab';

@Component({
  selector: 'other-user-basic-details',
  templateUrl: './other-user-basic-details.component.html',
  styleUrl: './other-user-basic-details.component.css'
})
export class OtherUserBasicDetailsComponent {
showLoader = true;
  userloggedIn: string;
  imageToDisplay: HostWindowModel;
  mc: ViewOtherProfilePrimaryTab = {
    UserId: '',
    CandidateGender: '',
    Age: '',
    Complexion: '',
    Height: '',
    Weight: '',
    BodyType: '',
    MaritalStatus: '',
    Physicalstatus: '',
    FamilyStatus: '',
    BloodGroup: ''
  };
  userWhosInformationIsRequired: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute, private signalStateService: SignalStateService) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;
    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getPrimaryUserInformation();
    });
  }

  getPrimaryUserInformation() {
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;;
      self.imageToDisplay = {} as HostWindowModel;
      self.commonService.getOtherUserProfilePrimary(self.userWhosInformationIsRequired, self.userloggedIn).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }
}
