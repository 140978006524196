<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>
<ng-containter *ngIf="!showLoader">
    <p-panel header="IMAGES" [toggleable]="false">
        <div *ngIf="images" class="grid">
            <div *ngFor="let image of images; let index = index" class="col-2">
                <img [src]="image.CandidateImage" class="image-shell" (click)="makeitbig(image.ImageName, userloggedIn)"
                    [alt]="image.ImageName" />
            </div>
        </div>
    </p-panel>
    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '39vw', height:'100vh' }" [closable]="true">
        <img [src]="mainImage" class="large-image" />
    </p-dialog>

</ng-containter>