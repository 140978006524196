<page>
  <ng-template>
    <div style="padding-top: 16px">
      <p-fieldset legend="Advanced Search">
        <div class="advanced-search-case">
          <ng-container [ngSwitch]="currentDeviceState">

            <ng-container *ngSwitchCase="'desktop-landscape'">
              <ng-container *ngTemplateOutlet="desktopLandscapeSearchPanel"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'mobile-portrait'">
              <ng-container *ngTemplateOutlet="mobilePortraitSearchPanel"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'tablet-portrait'">
              <ng-container *ngTemplateOutlet="mobilePortraitSearchPanel"></ng-container>
            </ng-container>

          </ng-container>
          <div *ngIf="searchResult.length > 0">
            <div class="panel panel-default b-r-1">
              <div class="panel-heading my-title b-c-t-i remove-border">
                <h3 class="panel-title">SEARCH RESULT</h3>
              </div>
              <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">
                <div class="grid-container">
                  <ng-container [ngSwitch]="currentDeviceState">

                    <ng-container *ngSwitchCase="'desktop-landscape'">
                      <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'mobile-portrait'">
                      <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'tablet-portrait'">
                      <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                    </ng-container>

                  </ng-container>

                </div>
                <div class="grid-footer" *ngIf="searchResult?.length>0">
                  <p-divider />
                  <ng-container [ngSwitch]="currentDeviceState">
                    <ng-container *ngSwitchCase="'desktop-landscape'">
                      <ng-container *ngTemplateOutlet="desktopLandscapeFooter"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'mobile-portrait'">
                      <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'tablet-portrait'">
                      <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>

          </div>

          <div *ngIf="searchResult?.length === 0 && noresult" class="empty-response"> No Result</div>
        </div>
      </p-fieldset>
    </div>
    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>

    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>

    <p-toast position="bottom-right"></p-toast>
    <p-confirmPopup></p-confirmPopup>
    <p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
      <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
        (OnSubmit)="eventPostSubmit($event)"></send-message>
    </p-dialog>

    <ng-template #mobilePortrait>
      <div *ngFor="let grid of searchResult" class="flex flex-column">
        <div class="flex flex-row">
          <div class="col-10">
            <img [src]="grid.RealImage" (click)="
            makeitbig(
              grid.ImagePathThumbnail,
              grid.CandidateUsername
            )
          " class="profile-image thumbnail-image" />
          </div>
          <div class="col-2">
            <p-inplace>
              <ng-template pTemplate="display">
                <p-button icon="pi pi-cog" [rounded]="true" severity="success" [outlined]="true" />
              </ng-template>
              <ng-template pTemplate="content">
                <span>
                  <div class="ibo-1">
                    <img pTooltip="VIEW PROFILE" tooltipPosition="top"
                      src="../../../../assets/image/newicons/id-card.png" class="rc-ito-3" alt=""
                      (click)="navigateToDetails(grid)" tooltipStyleClass="custom-tooltip" />
                  </div>
                  <div class="ibo-2">
                    <img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
                      src="../../../../assets/image/newicons/love-message-4669.png" class="rc-ito-3" alt=""
                      (click)="expressInterestPop($event, grid)" tooltipStyleClass="custom-tooltip" />
                  </div>
                  <div class="ibo-3">
                    <img pTooltip="SEND MESSAGE" tooltipPosition="top"
                      src="../../../../assets/image/newicons/envelope.png" class="rc-ito-3" alt=""
                      (click)="showSendDialogue(grid)" tooltipStyleClass="custom-tooltip" />
                  </div>
                  <div class="ibo-2">
                    <img pTooltip="BOOKMARK" tooltipPosition="top" src="../../../../assets/image/newicons/bookmark.png"
                      class="rc-ito-3" alt="" (click)="bookmarkprofile(grid)" tooltipStyleClass="custom-tooltip" />
                  </div>
                </span>
              </ng-template>
            </p-inplace>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Name</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.CandidateName }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">ID</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.CandidateUsername }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Age</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.CandidateAge }} Years</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Height</div>
          <div class="col-8">
            <strong> : {{ grid.CandidateHeight }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Occupation</div>
          <div class="col-8">
            <strong> : {{ grid.CandidateOccupation }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Work Place</div>
          <div class="col-8">
            <strong> : {{ grid.CandidateWorkplace }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Complexion</div>
          <div class="col-8">
            <strong>: {{ grid.CandidateComplexion }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Nationality</div>
          <div class="col-8">
            <strong>: {{ grid.Nationality }}</strong>
          </div>
        </div>


        <div class="flex flex-row text-block">
          <div class="col-4">City</div>
          <div class="col-8">
            <strong>: {{ grid.CandidateCity }}</strong>
          </div>
        </div>


        <div class="flex flex-row text-block">
          <div class="col-4">Firm Working In</div>
          <div class="col-8">
            <strong>: {{ grid.CandidateNameFirm }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Siblings</div>
          <div class="col-8">
            <strong>: {{ grid.CandidateSiblingDetails }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Weight</div>
          <div class="col-8">
            <strong>: {{ grid.CandidateWeight }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Parent Settled At</div>
          <div class="col-8">
            <strong>: {{ grid.Parentssettledat }}</strong>
          </div>
        </div>

        <div class="flex flex-row text-block">
          <div class="col-4">Membership Type</div>
          <div class="col-8">
            <strong>: {{ grid.MemberShipType }}</strong>
          </div>
        </div>


        <p-divider></p-divider>
      </div>
    </ng-template>

    <ng-template #desktopLandscape>
      <p-table [value]="searchResult" [lazy]="true">
        <ng-template pTemplate="header" let-columns>
          <tr class="make-quick-sand-medium text-block">
            <th>
              Name
            </th>
            <th>Picture</th>
            <th>
              Nationality
            </th>
            <th>
              City
            </th>
            <th>
              Membership Type
            </th>
            <th>
              Denomination

            </th>
            <th>
              Height
            </th>
            <th>
              Occupation
            </th>
            <th>
              Age
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-grid let-columns="columns">
          <tr class="make-quick-sand-medium">
            <td class="text-block">{{ grid.CandidateName }}<br />{{ grid.CandidateUsername }}</td>
            <td style="padding-left: 10px; width: 15%">

              <img [src]="grid.RealImage" (click)="
          makeitbig(
            grid.ImagePathThumbnail,
            grid.CandidateUsername
          )
        " class="profile-image thumbnail-image" />
            </td>
            <td class="text-block">{{ grid.Nationality }}</td>
            <td class="text-block">{{ grid.CandidateCity }}</td>
            <td class="text-block">{{ grid.MemberShipType }}</td>
            <td class="text-block">{{ grid.CandidateDenomination }}</td>
            <td class="text-block">{{ grid.CandidateHeight }}</td>
            <td class="text-block">{{ grid.CandidateOccupation }}</td>
            <td class="text-block">{{ grid.CandidateAge }}</td>
            <td>
              <div class="action-box-2 text-right">
                <!-- <p-inplace>
                  <ng-template pTemplate="display">
                    <p-button icon="pi pi-cog" [rounded]="true" severity="success" [outlined]="true" />
                  </ng-template>
                  <ng-template pTemplate="content">
                  
                  </ng-template>
                </p-inplace> -->

                <div class="ibo-1">

                  <div class="rc-ito-3" pTooltip="VIEW PROFILE" style="font-size: 1.1rem" tooltipPosition="left"
                    (click)="navigateToDetails(grid)" tooltipStyleClass="custom-tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                      stroke="#25C7D9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-contact  zoom-icon add-cursor">
                      <path d="M16 2v2" />
                      <path d="M7 22v-2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2" />
                      <path d="M8 2v2" />
                      <circle cx="12" cy="11" r="3" />
                      <rect x="3" y="4" width="18" height="18" rx="2" />
                    </svg>
                  </div>


                </div>
                <div class="ibo-1">


                  <div style="font-size: 1.1rem" pTooltip="Express Interest" tooltipPosition="left"
                    (click)="expressInterestPop($event, grid)" tooltipStyleClass="custom-tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                      stroke="#e63946" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-hand-heart zoom-icon add-cursor">
                      <path d="M11 14h2a2 2 0 1 0 0-4h-3c-.6 0-1.1.2-1.4.6L3 16" />
                      <path
                        d="m7 20 1.6-1.4c.3-.4.8-.6 1.4-.6h4c1.1 0 2.1-.4 2.8-1.2l4.6-4.4a2 2 0 0 0-2.75-2.91l-4.2 3.9" />
                      <path d="m2 15 6 6" />
                      <path
                        d="M19.5 8.5c.7-.7 1.5-1.6 1.5-2.7A2.73 2.73 0 0 0 16 4a2.78 2.78 0 0 0-5 1.8c0 1.2.8 2 1.5 2.8L16 12Z" />
                    </svg>

                  </div>
                </div>
                <div class="ibo-1">


                  <div pTooltip="Send message" style="font-size: 1.1rem" tooltipPosition="left"
                    (click)="showSendDialogue(grid)" tooltipStyleClass="custom-tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                      stroke="#e67e22" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-message-square-text zoom-icon add-cursor">
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                      <path d="M13 8H7" />
                      <path d="M17 12H7" />
                    </svg>
                  </div>



                </div>
                <div class="ibo-1">
                  <div pTooltip="BOOKMARK" tooltipPosition="top" (click)="bookmarkprofile(grid)"
                    tooltipStyleClass="custom-tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                      stroke="#70e3ff" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-bookmark-plus zoom-icon add-cursor">
                      <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z" />
                      <line x1="12" x2="12" y1="7" y2="13" />
                      <line x1="15" x2="9" y1="10" y2="10" />
                    </svg>
                  </div>
                </div>

                <div class="ibo-1">
                  <div pTooltip="Chat" tooltipPosition="top" (click)="invokeChat(grid)"
                    tooltipStyleClass="custom-tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                      stroke="#2DD4BF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-message-circle zoom-icon add-cursor">
                      <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
                    </svg>
                  </div>
                </div>


              </div>
            </td>
          </tr>
        </ng-template>

        <!-- Optionally, you can define custom paginator buttons here -->
        <ng-template pTemplate="paginatorleft">
          <p-button type="button" styleClass="p-button-text"></p-button>
        </ng-template>

        <ng-template pTemplate="paginatorright">
          <p-button type="button" styleClass="p-button-text"></p-button>
        </ng-template>

        <ng-template pTemplate="caption">
          Total Records: {{totalRecords}}
        </ng-template>

      </p-table>
    </ng-template>

    <ng-template #mobilePortraitSearchPanel>
      <div *ngIf="showSearchBox">

        <div class="flex flex-wrap  py-1">
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="ageArrayFrom" [(ngModel)]="ageFromSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="From Age"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="ageArrayTo" [(ngModel)]="ageToSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="To Age"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="heightArrayFrom" [(ngModel)]="heightFromSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="From Height"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="heightArrayTo" [(ngModel)]="heightToSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="To Height"></p-dropdown>
          </div>
        </div>

        <div class="flex flex-wrap  py-1">
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="maritalStatus" [(ngModel)]="maritalStatusSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Marital Status"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="physicalStatus" [(ngModel)]="physicalStatusSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Physical Status"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="complexionList" [(ngModel)]="complexionSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Complexion"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="jobCategoryList" [(ngModel)]="jobCategorySelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Job Category"></p-dropdown>
          </div>
        </div>

        <div class="flex flex-wrap  py-1">
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="qualificationList" [(ngModel)]="qualificationSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Qualification"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="religionList" [(ngModel)]="religionSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Religion"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="workplaceList" [(ngModel)]="candidatesWorkingNationalitySelectedValue"
              optionLabel="Name" [style]="{ width: '100%', overflow: 'visible' }"
              placeholder="Working Country"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="fathersPlace" [(ngModel)]="fathersWorkingNationalitySelectedValue" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Fathers Working Country"></p-dropdown>
          </div>
        </div>

        <div class="flex flex-wrap  py-1 mt-2 mb-2">
          <div class="col-12 sm:col-6 md:col-4 lg:col-1">Looking For</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-2">
            <div class="horizontal-list">
              <ng-container *ngFor="let gender of groomOrBride.source" class="field-checkbox"
                style="margin-right: 10px !important">
                <p-radioButton [inputId]="gender" Name="gender" [value]="gender"
                  [(ngModel)]="groomOrBride.selectedValue"></p-radioButton>
                <label [for]="gender">{{ gender.Name }}</label>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap  py-1 mt-2 mb-2">
          <div class="col-12 sm:col-6 md:col-4 lg:col-1">Sort By</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-4 horizontal-list">
            <ng-container *ngFor="let sortBy of sortBySource.source" class="field-checkbox"
              style="margin-right: 10px !important">
              <p-radioButton [inputId]="sortBy" Name="sortBy" [value]="sortBy"
                [(ngModel)]="sortBySource.selectedValue"></p-radioButton>
              <label [for]="sortBy">{{ sortBy.Name }}</label>
            </ng-container>
          </div>
        </div>
        <div class="flex flex-wrap py-1">
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <div class="flex flex-wrap py-1">
              <div class="col-12 sm:col-6 md:col-4 lg:col-8 sub-label-display">
                With Photo
              </div>
              <div class="col-12 sm:col-6 md:col-4 lg:col-4 py-2">
                <div class="form-check position-checkbox">
                  <p-checkbox [(ngModel)]="profileWithImage" [binary]="true"></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3 sub-label-display"
            style="display: flex;flex-direction: row;flex-wrap: wrap;">
            <div class="col-12 sm:col-6 md:col-4 lg:col-11  py-2">
              Save Search
            </div>
            <div class="flex flex-row" style="width: 100%;">
              <div class="form-check position-checkbox-mobile col-2">
                <p-checkbox [(ngModel)]="saveSearch" [binary]="true"></p-checkbox>
              </div>
              <div class="col-10">
                @if(saveSearch){
                <div class="flex flex-row">
                  <div class="col-10">
                    <input type="text" placeholder="Search Name" class="inp-save-search" pInputText
                      [(ngModel)]="searchName" style="width: 92%;" />
                  </div>
                  <div class="col-2">
                    <button type="button" pButton icon="pi pi-save" class="p-button-success"
                      (click)="saveSearchName()"></button>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-1 text-center">
            <div *ngIf="resultCount > 0" class="result-count-display">
              Result Count: {{ resultCount }}
            </div>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3 text-left flex flex-row">
            <div>
              <button pButton type="button" label="Search" class="cb-blue" (click)="triggerSearch(true)"></button>
            </div>
            <div style="margin-left: 10px;">
              <button pButton (click)="resetSearch()" type="button" class="cb-red">
                Reset
              </button>
            </div>
          </div>
        </div>


        <div class="custom-hr b-p-5" *ngIf="!showSearchBox"></div>
      </div>
      <p-divider type="solid"></p-divider>
      <div *ngIf="!showSearchBox">
        <div class="show-hide-button-div">
          <div style="float: left">
            <div class="m-t-8-m h-c search-title">ADVANCED SEARCH</div>
          </div>
          <div style="float: right">
            <button (click)="hideSearchBox()" class="hyperlink-button">
              SEARCH PANEL
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #desktopLandscapeSearchPanel>
      <div *ngIf="showSearchBox">

        <div class="flex flex-wrap  py-1">
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="ageArrayFrom" [(ngModel)]="ageFromSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="From Age"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="ageArrayTo" [(ngModel)]="ageToSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="To Age"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="heightArrayFrom" [(ngModel)]="heightFromSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="From Height"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="heightArrayTo" [(ngModel)]="heightToSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="To Height"></p-dropdown>
          </div>
        </div>

        <div class="flex flex-wrap  py-1">
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="maritalStatus" [(ngModel)]="maritalStatusSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Marital Status"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="physicalStatus" [(ngModel)]="physicalStatusSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Physical Status"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="complexionList" [(ngModel)]="complexionSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Complexion"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="jobCategoryList" [(ngModel)]="jobCategorySelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Job Category"></p-dropdown>
          </div>
        </div>

        <div class="flex flex-wrap  py-1">
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="qualificationList" [(ngModel)]="qualificationSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Qualification"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="religionList" [(ngModel)]="religionSelected" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Religion"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="workplaceList" [(ngModel)]="candidatesWorkingNationalitySelectedValue"
              optionLabel="Name" [style]="{ width: '100%', overflow: 'visible' }"
              placeholder="Working Country"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-dropdown [options]="fathersPlace" [(ngModel)]="fathersWorkingNationalitySelectedValue" optionLabel="Name"
              [style]="{ width: '100%', overflow: 'visible' }" placeholder="Fathers Working Country"></p-dropdown>
          </div>
        </div>

        <div class="flex flex-wrap  py-1 mt-2 mb-2">
          <div class="col-12 sm:col-6 md:col-4 lg:col-1">Looking For</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-2">
            <div class="horizontal-list">
              <ng-container *ngFor="let gender of groomOrBride.source" class="field-checkbox"
                style="margin-right: 10px !important">
                <p-radioButton [inputId]="gender" Name="gender" [value]="gender"
                  [(ngModel)]="groomOrBride.selectedValue"></p-radioButton>
                <label [for]="gender">{{ gender.Name }}</label>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap  py-1 mt-2 mb-2">
          <div class="col-12 sm:col-6 md:col-4 lg:col-1">Sort By</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-4 horizontal-list">
            <ng-container *ngFor="let sortBy of sortBySource.source" class="field-checkbox"
              style="margin-right: 10px !important">
              <p-radioButton [inputId]="sortBy" Name="sortBy" [value]="sortBy"
                [(ngModel)]="sortBySource.selectedValue"></p-radioButton>
              <label [for]="sortBy">{{ sortBy.Name }}</label>
            </ng-container>
          </div>
        </div>

        <div class="flex flex-wrap  py-1">
          <div class="col-12 sm:col-6 md:col-4 lg:col-1">With Photo</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-1">
            <p-checkbox [(ngModel)]="profileWithImage" [binary]="true"></p-checkbox>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-2 text-left horizontal-list">
            Save Search
            <div style="padding-left: 15px;">
              <p-checkbox [(ngModel)]="saveSearch" [binary]="true"></p-checkbox>
            </div>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-2  text-left" *ngIf="saveSearch">
            <ng-containter>
              <p-inputGroup>
                <input type="text" placeholder="Search Name" class="inp-save-search" pInputText
                  [(ngModel)]="searchName" />
                <button type="button" pButton icon="pi pi-save" class="p-button-success"
                  (click)="saveSearchName()"></button>
              </p-inputGroup>
            </ng-containter>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-2" *ngIf="!saveSearch"></div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-2"></div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-2"></div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-1 text-right">
            <button pButton (click)="resetSearch()" type="button" class="cb-red">
              Reset
            </button>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-1 text-right">
            <button pButton (click)="triggerSearch(true)" class="cb-blue">Search</button>
          </div>
        </div>


        <div class="custom-hr b-p-5" *ngIf="!showSearchBox"></div>
      </div>
      <p-divider type="solid"></p-divider>
      <div *ngIf="!showSearchBox">
        <div class="show-hide-button-div">
          <div style="float: left">
            <div class="m-t-8-m h-c search-title">ADVANCED SEARCH</div>
          </div>
          <div style="float: right">
            <button (click)="hideSearchBox()" class="hyperlink-button">
              SEARCH PANEL
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #mobilePortraitFooter>
      <div class="flex py-1">
        <div class="col-12 justify-content-center">
          <ng-container *ngIf="searchResult?.length > 0">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true"
              [pageLinks]="5" [showPageLinks]="true" [showJumpToPageDropdown]="false"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template #desktopLandscapeFooter>
      <div class="flex py-1">
        <div class="col-1 align-footer">
          <span class="label label-primary">Total Count {{ sortedData?.length }}</span>
        </div>

        <div class="col-9 justify-content-center">
          <ng-container *ngIf="searchResult?.length > 0">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true"
              [pageLinks]="5" [showPageLinks]="true" [showJumpToPageDropdown]="false"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
          </ng-container>
        </div>

        <div class="col-2 text-right">
          <button pButton *ngIf="searchResult?.length > 0" (click)="openMultiDeleteModal()">
            Delete
          </button>
        </div>
      </div>
    </ng-template>

  </ng-template>



</page>

<p-dialog [(visible)]="viewProfileVisible" [modal]="true" [closable]="true" [style]="{width: '80vw', height:'85vh'}">
  <ng-template pTemplate=header>
    <div class="pl-5 other-prifle-title">{{nameOfPersonsProfileBeingSeen}}</div>
  </ng-template>
  <div *ngIf="viewProfileVisible">
    <app-viewotherprofile [userId]="selectedUserId"></app-viewotherprofile>
  </div>
</p-dialog>