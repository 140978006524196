<page>
  <ng-template>


    <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
      <div class="flex justify-content-between flex-wrap">
        <div class="flex align-items-center">BOOKMARKS
          <i class="pi pi-bookmark"></i>
        </div>

        <div *ngIf="showSearchIcon">
          <i class="pi pi-search super-search-icon" (click)="showSearchIcon=!showSearchIcon"></i>
        </div>
        <div *ngIf="!showSearchIcon" @fadeInOut class="panel-search">

          <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
            (ngModelChange)="globalSearch($event)" />

        </div>
      </div>
    </div>
    <p-card legend="" *ngIf="listOfbookmark?.length > 0" style="margin-left: 0px;">

      <mycard>
        <div class="barelist">
          <div *ngFor="
              let bookmark of listOfbookmark | paginate : { itemsPerPage: 10, currentPage: p }
            ">

            <div class="flex flex-wrap align-items-center justify-content-between custom-subset-card">
              <div class="col-12 md:col-2 mb-2 md:mb-0">
                <div class="flex justify-content-center">

                  <img [src]="bookmark.candidateProfileImageReal"
                    (click)="makeitbig(bookmark.candidateProfileImageTmb, bookmark.userWhoGotBookMarked)"
                    class="profile-image thumbnail-image" [ngClass]="
                          bookmark.candidateProfileImageReal
                            ? 'profile-image thumbnail-image'
                            : 'profile-image thumbnail-image-no-cursor'
                        " alt="Candidate Image" />

                </div>
              </div>

              <div class="col-12 md:col-9">
                <div class="barelist text-block">
                  <div class="flex flex-column md:flex-row">
                    <div class="col-12 md:col-4 flex flex-column md:flex-row">
                      <div class="col-12 md:col-1" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                        pTooltip="Name">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                          stroke="#E11D48" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-user">
                          <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                          <circle cx="12" cy="7" r="4" />
                        </svg>
                      </div>
                      <div class="col-12 md:col-11 ">
                        : <span class="pl-4">
                          <strong> {{ bookmark.candidate_name }}</strong>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 md:col-4  flex flex-column md:flex-row">
                      <div class="col-12 md:col-1" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                        pTooltip="SHM ID">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                          stroke="#D97706 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-id-card">
                          <path d="M16 10h2" />
                          <path d="M16 14h2" />
                          <path d="M6.17 15a3 3 0 0 1 5.66 0" />
                          <circle cx="9" cy="11" r="2" />
                          <rect x="2" y="5" width="20" height="14" rx="2" />
                        </svg>

                      </div>
                      <div class="col-12 md:col-11">
                        : <span class="pl-4">
                          <strong> {{ bookmark.candidate_Username }}</strong>
                        </span>
                      </div>
                    </div>
                  </div>



                  <div class="flex flex-column md:flex-row">
                    <div class="col-12 md:col-4 flex flex-column md:flex-row">
                      <div class="col-12 md:col-1" tooltipPosition="top" tooltipStyleClass="custom-tooltip"
                        pTooltip="Age">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                          stroke="#059669 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-calendar-clock">
                          <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5" />
                          <path d="M16 2v4" />
                          <path d="M8 2v4" />
                          <path d="M3 10h5" />
                          <path d="M17.5 17.5 16 16.3V14" />
                          <circle cx="16" cy="16" r="6" />
                        </svg>
                      </div>
                      <div class="col-12 md:col-11">
                        : <span class="pl-4">
                          <strong> {{ bookmark.candidate_Age }} Years</strong>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 md:col-4  flex flex-column md:flex-row">
                      <div class="col-12 md:col-1" tooltipPosition="top" tooltipStyleClass="custom-tooltip"
                        pTooltip="Denomination">

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                          stroke="#4F46E5 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-church">
                          <path d="M10 9h4" />
                          <path d="M12 7v5" />
                          <path d="M14 22v-4a2 2 0 0 0-4 0v4" />
                          <path
                            d="M18 22V5.618a1 1 0 0 0-.553-.894l-4.553-2.277a2 2 0 0 0-1.788 0L6.553 4.724A1 1 0 0 0 6 5.618V22" />
                          <path
                            d="m18 7 3.447 1.724a1 1 0 0 1 .553.894V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.618a1 1 0 0 1 .553-.894L6 7" />
                        </svg>

                      </div>
                      <div class="col-12 md:col-11">
                        : <span class="pl-4">
                          {{ bookmark.candidate_Denomination }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-column md:flex-row">
                    <div class="col-12 md:col-4 flex flex-column md:flex-row">
                      <div class="col-12 md:col-1" tooltipPosition="top" tooltipStyleClass="custom-tooltip"
                        pTooltip="Education">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                          stroke="#9333EA " stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-graduation-cap">
                          <path
                            d="M21.42 10.922a1 1 0 0 0-.019-1.838L12.83 5.18a2 2 0 0 0-1.66 0L2.6 9.08a1 1 0 0 0 0 1.832l8.57 3.908a2 2 0 0 0 1.66 0z" />
                          <path d="M22 10v6" />
                          <path d="M6 12.5V16a6 3 0 0 0 12 0v-3.5" />
                        </svg>
                      </div>
                      <div class="col-12 md:col-11">
                        : <span class="pl-4">
                          {{ bookmark.Education }}
                        </span>
                      </div>
                    </div>
                    <div class="col-12 md:col-4  flex flex-column md:flex-row">
                      <div class="col-12 md:col-1" tooltipPosition="top" tooltipStyleClass="custom-tooltip"
                        pTooltip="Occupation">

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                          stroke="#0D9488 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-briefcase">
                          <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
                          <rect width="20" height="14" x="2" y="6" rx="2" />
                        </svg>

                      </div>
                      <div class="col-12 md:col-11">
                        : <span class="pl-4">
                          {{ bookmark.candidate_Occupation }}
                        </span>
                      </div>
                    </div>
                  </div>


                  <div class="flex flex-column md:flex-row">
                    <div class="col-12 md:col-4 flex flex-column md:flex-row">
                      <div class="col-12 md:col-1" tooltipPosition="top" tooltipStyleClass="custom-tooltip"
                        pTooltip="Workplace">

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                          stroke="#2563EB " stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-building-2">
                          <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" />
                          <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" />
                          <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" />
                          <path d="M10 6h4" />
                          <path d="M10 10h4" />
                          <path d="M10 14h4" />
                          <path d="M10 18h4" />
                        </svg>

                      </div>
                      <div class="col-12 md:col-11">
                        : <span class="pl-4"> {{ bookmark.candidate_Workplace }}</span>
                      </div>
                    </div>
                    <div class="col-12 md:col-4  flex flex-column md:flex-row">
                      <div class="col-12 md:col-1" tooltipPosition="top" tooltipStyleClass="custom-tooltip"
                        pTooltip="Brief Partner Expections">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                          stroke="#DB2777 " stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-heart-handshake">
                          <path
                            d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
                          <path
                            d="M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66" />
                          <path d="m18 15-2-2" />
                          <path d="m15 18-2-2" />
                        </svg>

                      </div>
                      <div class="col-12 md:col-11 interest-in">
                        : <span class="pl-4"> {{ bookmark.Partner_Expectations_brief }}</span>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

              <div class="col-12 md:col-1 text-right" style="margin: 0px; padding: 0px;">
                <div class="action-box">
                  <card-member-actions [userAction]="{
                      fromId: bookmark.UserWhoBookMarked,
                      userToId: bookmark.userWhoGotBookMarked,
                      userToName: bookmark?.candidate_name?.toUpperCase(),
                      ShowSendMessage: false,
                      sendMessageText: sendMessageText ?? '',
                      Id: bookmark.bookMarkId,
                      tableToDelete:'BookMark'
                    }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>
                </div>


                <!-- <button (click)="invokeChat(bookmark)"> chat</button> -->
              </div>
            </div>
            <br />
          </div>

        </div>

        <ImageModelWindow [tc]="imageToDisplay"></ImageModelWindow>
      </mycard>


      <pagination-controls (pageChange)="p = $event"></pagination-controls>


    </p-card>

    <p-toast position="bottom-right"></p-toast>

    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>


    <div *ngIf="listOfbookmark?.length === 0 && !showLoader" class="no-value-text-ic">
      No Bookmarks yet
    </div>



    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>

    <p-confirmDialog></p-confirmDialog>
  </ng-template>
</page>