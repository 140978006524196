import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { CandidateReliionBackGround } from 'src/app/private/profile/view/CandidateReliionBackGround';

@Component({
  selector: 'other-user-religion-details',
  templateUrl: './other-user-religion-details.component.html',
  styleUrl: './other-user-religion-details.component.css'
})
export class OtherUserReligionDetailsComponent {
 mc: CandidateReliionBackGround = {
    Denomination: '',
    Diocese: '',
    CandidateNative: '',
    CityOrDistrict: '',
    CurrentAddress: ''
  };
  showLoader = true;
  userloggedIn: string;
  userWhosInformationIsRequired: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute, private signalStateService: SignalStateService) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;
    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getPrimaryUserInformation();
    });
  }
  getPrimaryUserInformation() {
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getOtherProfileReligiosSocialBackGroundTab(self.userWhosInformationIsRequired).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }
}
