import { Component, Input, ViewChild } from '@angular/core';
import { HostWindowModel } from '../model/hostmodel/hostmodel.domain';
import { Commonservice } from '../servicecenter/commonservice.component';
import { ExpressInterestCarrier } from 'src/app/dto/ExpressInterestCarrier';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { ExpressInterestModel } from 'src/app/dto/ExpressInterestModel';
import { ExpressInterestSendModel } from 'src/app/dto/ExpressInterestSendModel';
import { SendMessageGeneric } from 'src/app/dto/SendMessageGeneric';
import { SendMessageComponent } from '../send-message/send-message.component';
import { AddBookmarkModel } from 'src/app/dto/AddBookmarkModel';
import { PrefetchBackGroundService } from '../servicecenter/PrefetchBackGroundService';
import { Router } from '@angular/router';

@Component({
  selector: 'large-image-window',
  templateUrl: './large-image-window.component.html',
  styleUrls: ['./large-image-window.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class LargeImageWindowComponent {
  @Input() tc: HostWindowModel;
  userloggedIn: string;
  mtd: ExpressInterestModel;
  confirmationTitle: string = "Confirmation";
  expressInterestModel: ExpressInterestSendModel;
  sendMessageText: string;
  userBeingViewed: string;
  showLoader = false;
  smg: SendMessageGeneric;
  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;


  constructor(private datePipe: DatePipe, private commonService: Commonservice, private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router, private localStore: LocalStore, private pgbs: PrefetchBackGroundService) {
    const self = this;
    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName
  }

  callLargeImage(thumbnailId) {
    const self = this;
    let largerImageId = thumbnailId.replace("Tmb", "");
    self.showLoader = true;
    self.commonService.getLargeMainImage(largerImageId).subscribe(
      data => {
        self.tc.MainImage = data.MainImage;
        self.showLoader = false;
      },
      error => {

      }
    );
  }

  expressInterestPop(event: Event) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = self.tc.UserId;
    eic.userToName = self.tc.UserId.toUpperCase();
    self.mtd = self.commonService.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  sendExpressInterest(event: ExpressInterestModel) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonService.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  bookmarkprofile() {
    const self = this;
    let bookmark: AddBookmarkModel = {
      UserWhoIsBookmarking: self.userloggedIn,
      UserWhoIsGettingBookmarked: self.tc.UserId
    }
    let error: any;
    self.commonService.AddBookMark(bookmark).subscribe(
      data => {
        if (data != null) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
          // this.pgbs.updateHomePageBookMarkInStore();
          // this.pgbs.updateBookMarkInStore();
        }
        error = data?.Message;
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Service Message', detail: error });
      }
    );
  }

  navigateToDetails(tc: HostWindowModel) {
    let link = tc.RouterPath + "/" + tc.UserId;
    this.router.navigate([]).then(result => { window.open(link, '_blank'); });
  }
}
