<publicpage>
  <!-- <p-card header="Register"> -->
  <form novalidate [formGroup]="primaryInformationFormGroup" style="height: 100%;">
    <!-- <ng-containter *ngIf="showFloatingLoader">
      <loader></loader>
    </ng-containter>
 -->
    <super-floating-loader [isLoading]="showFloatingLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>

    <div class="card flex justify-content-center p-5">
      <p-stepper >
        <p-stepperPanel header="FORM">
          <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
            <div class="flex flex-column">


              <div class="formgrid grid">
                <div class="field col-12 md:col-4">
                  <label for="userEmail">Email</label>
                  <input type="text" pInputText formControlName="userEmail" placeholder="Email"
                    [class]="'full-width'" />

                  <div *ngIf="userEmail.invalid && (userEmail.dirty || userEmail.touched)">
                    <div *ngIf="userEmail.errors?.['required']">Email is required.</div>

                    <div *ngIf="userEmail.errors?.['minlength'] && !userEmail.errors?.['required']">
                      minimum Length of 3 Characters.
                    </div>

                    <div
                      *ngIf="userEmail.errors?.['EmailValidationFailed']  && !userEmail.errors?.['required'] && !userEmail.errors?.['minlength']">
                      Proper Email Required
                    </div>

                    <div *ngIf="userEmail.errors?.['emailExists'] && !userEmail.errors?.['required']">
                      Email already exists.
                    </div>
                  </div>
                </div>

                <div class="field col-12 md:col-4">
                  <label for="inpPhoneNumber">Phone Number</label>
                  <input id="inpDateOfBirth" type="text" [class]="'full-width'" pInputText
                    formControlName="inpPhoneNumber" placeholder="Phone Number" />

                  <div *ngIf="genericInputValidator('inpPhoneNumber')">
                    Required minimum length is 3
                  </div>

                  <div
                    *ngIf="primaryInformationFormGroup.get('inpPhoneNumber')?.touched && primaryInformationFormGroup.get('inpPhoneNumber')?.errors?.['phoneExists']">
                    Phone number already exists.
                  </div>
                </div>

                <div class="field col-12 md:col-4">
                  <label for="inpPassword">Password</label>
                  <input id="inpPassword" type="password" placeholder="Password" [class]="'full-width'" pInputText
                    formControlName="inpPassword" />

                  <div *ngIf="genericInputValidator('inpPassword')">
                    Required miniimum length is 3
                  </div>
                </div>
              </div>

              <div class="formgrid grid">
                <div class="field col-12 md:col-4">
                  <label for="inpFirstName">First Name</label>
                  <input id="inpFirstName" type="text" [class]="'full-width'" placeholder="First Name" pInputText
                    formControlName="inpFirstName" />

                  <div *ngIf="genericInputValidator('inpFirstName')">
                    Required miniimum length is 3
                  </div>
                </div>
                <div class="field col-12 md:col-4">
                  <label for="inpMiddleName">Middle Name</label>
                  <input id="inpMiddleName" type="text" [class]="'full-width'" placeholder="Middle Name" pInputText
                    formControlName="inpMiddleName" />

                  <div *ngIf="genericInputValidator('inpMiddleName')">
                    Required miniimum length is 3
                  </div>
                </div>
                <div class="field col-12 md:col-4">
                  <label for="inpLastName">Last Name</label>
                  <input id="inpLastName" type="text" [class]="'full-width'" placeholder="Last Name" pInputText
                    formControlName="inpLastName" />

                  <div *ngIf="genericInputValidator('inpLastName')">
                    Required miniimum length is 3
                  </div>
                </div>
              </div>

              <div class="grid">
                <div class="field col-12 md:col-4">
                  <label for="inpMaritalStatus">Marital Status</label>
                  <p-dropdown [options]="userMartialStatus" formControlName="inpMaritalStatus" optionLabel="name"
                    optionValue="value" [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="name"
                    [showClear]="true" placeholder="Select"></p-dropdown>

                  <div *ngIf="genericDropDownListValidator('inpMaritalStatus')">
                    please select at lease one value from list
                  </div>
                </div>
                <div class="field col-12 md:col-4">
                  <label for="inpReligion">Religion/Denomination</label>
                  <p-dropdown [options]="userReligionDenomination" id="inpReligion" formControlName="inpReligion"
                    optionLabel="name" optionValue="value" [style]="{ width: '100%', overflow: 'visible' }"
                    [filter]="true" filterBy="name" [showClear]="true" placeholder="Select"></p-dropdown>
                  <div *ngIf="genericDropDownListValidator('inpReligion')">
                    please select at lease one value from list
                  </div>
                </div>
                <div class="field col-12 md:col-4">
                  <label for="icon">Date Of Birth</label>
                  <p-calendar formControlName="inpDateOfBirth" placeholder="Date Of Birth" [showIcon]="true"
                    inputId="icon" [style]="{ width: '100%', overflow: 'visible' }"></p-calendar>
                </div>
              </div>

            </div>
            <div class="flex pt-4 justify-content-end  p-3">
              <p-button label="Next" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit()" />
            </div>
          </ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="FORM">
          <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
            let-index="index">
            <div class="flex flex-column">
              <div class="grid">


                <div class="field col-12 md:col-4">
                  <label for="inpHeight">Height</label>
                  <p-dropdown [options]="height" id="inpHeight" formControlName="inpHeight" optionLabel="name"
                    optionValue="value" [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="name"
                    [showClear]="true" placeholder="Select"></p-dropdown>

                  <div *ngIf="genericDropDownListValidator('inpHeight')">
                    please select at lease one value from list
                  </div>
                </div>

                <div class="field col-12 md:col-4">
                  <label for="inpGender">Occupation</label>
                  <p-dropdown [options]="occupations" id="inpOccupation" formControlName="inpOccupation"
                    optionLabel="name" [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="name"
                    [showClear]="true" placeholder="Select"></p-dropdown>

                  <div *ngIf="genericDropDownListValidator('inpOccupation')">
                    please select at lease one value from list
                  </div>
                </div>



                <div class="field col-12 md:col-4">
                  <label for="inpGender">Gender</label>
                  <p-dropdown [options]="genders" id="inpGender" formControlName="inpGender" optionLabel="name"
                    optionValue="value" [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="name"
                    [showClear]="true" placeholder="Select"></p-dropdown>

                  <div *ngIf="genericDropDownListValidator('inpGender')">
                    please select at lease one value from list
                  </div>
                </div>
              </div>

              <div class="grid">


                <div class="field col-12 md:col-4">
                  <label for="inpNationality">Nationality</label>
                  <p-dropdown [options]="nationality" id="inpNationality" formControlName="inpNationality"
                    optionLabel="Name" optionValue="Value" [style]="{ width: '100%', overflow: 'visible' }"
                    [filter]="true" filterBy="name" [showClear]="true" (onChange)="onNationalityChange($event)"
                    placeholder="Select"></p-dropdown>
                </div>

                <div class="field col-12 md:col-4" *ngIf="showCity">
                  <ng-container>
                    <label for="inpCity">City</label>
                    <p-dropdown [options]="cities" id="inpCity" formControlName="inpCity" optionLabel="Name"
                      optionValue="Value" [style]="{ width: '100%', overflow: 'visible' }" [filter]="true"
                      filterBy="name" [showClear]="true" placeholder="Select"></p-dropdown>
                  </ng-container>
                </div>

                <div class="field col-12 md:col-4" *ngIf="showStates">
                  <ng-container>
                    <label for="inpState">State</label>
                    <p-dropdown [options]="states" id="inpState" formControlName="inpState" optionLabel="Name"
                      optionValue="Value" [style]="{ width: '100%', overflow: 'visible' }"
                      (onChange)="onStateChange($event)" [filter]="true" filterBy="name" [showClear]="true"
                      placeholder="Select"></p-dropdown>
                  </ng-container>
                </div>

              </div>

              <div class="grid justify-content-end">

                <div class="field col-12 md:col-4">
                  <label for="inpCouponCode">Coupon Code</label>
                  <input id="inpCouponCode" type="text" placeholder="Coupon Code" [class]="'full-width'" pInputText
                    formControlName="inpCouponCode" />
                </div>

              </div>



            </div>
            <div class="flex pt-4 justify-content-between gap-2 ">
              <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
              <div class="horizontal-list  p-3">
                <p-button label="Reset" (click)="resetRegister()" styleClass="p-button-danger"></p-button>
                <p-button label="Submit" (click)="saveNewUser()"
                  [disabled]="!primaryInformationFormGroup.valid"></p-button>
              </div>
            </div>
          </ng-template>
        </p-stepperPanel>
      </p-stepper>
    </div>
  </form>

  <p-toast position="bottom-right"></p-toast>
  <!-- </p-card> -->
</publicpage>