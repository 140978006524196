<page>
  <ng-template>
    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>
    <div class="text-block">

      <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center">EXPRESS INTEREST SENT TRASH <i class="pi pi-trash"></i></div>

          <div *ngIf="showSearchIcon">
            <i class="pi pi-search super-search-icon" (click)="showSearchIcon=!showSearchIcon"></i>
          </div>
          <div *ngIf="!showSearchIcon" @fadeInOut class="panel-search">

            <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
              (ngModelChange)="globalSearch($event)" />

          </div>
        </div>
      </div>


      <div class="panel panel-default b-r-1">
        <div class="panel-header-container">
        </div>
        <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">

          <div class="control-section standard-content">
            <div *ngIf="sortedDataSend?.length === 0" class="no-value-text-ic">
              No messages in express interest trash yet
            </div>
            <ng-container *ngIf="sortedDataSend?.length > 0">
              <ng-container [ngSwitch]="currentDeviceState">

                <ng-container *ngSwitchCase="'desktop-landscape'">
                  <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'mobile-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'tablet-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>

              </ng-container>
            </ng-container>
          </div>
          <div class="grid-footer">
            <p-divider />
            <ng-container [ngSwitch]="currentDeviceState">

              <ng-container *ngSwitchCase="'desktop-landscape'">
                <ng-container *ngTemplateOutlet="desktopLandscapeFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'mobile-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'tablet-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>

            </ng-container>
          </div>
        </div>
      </div>

    </div>

    <!-- ---------This section is for delete received ------ -->
    <ng-template [ngIf]="showExpressReceivedDelete">
      <div [ngClass]="[deletereceivedModel.show ? 'overlay-v' : 'overlay-h']">
        <div [ngClass]="[deletereceivedModel.show ? 'show-pop-v' : 'show-pop-h']">
          <div class="model-box">
            <div class="mb-1">
              <div class="mb-1-1"></div>
              <div class="mb-1-2" (click)="closeReceivedTrash()">X</div>
            </div>
            <div class="mb-1">
              <div>
                {{ deletereceivedModel.title }}
              </div>
            </div>
            <div class="mb-2">
              <div class="mb-2-1">
                <button type="button" (click)="deleteRecievedExpression(deletereceivedModel)" color="primary" pButton>
                  YES
                </button>
              </div>
              <div class="mb-2-2">
                <button type="button" (click)="closeReceivedTrash()" color="primary" pButton>
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- ---------This section is for restore received received ------ -->

    <!-- ---------This section is for restore received received ------ -->
    <ng-template [ngIf]="showExpressReceivedRestore">
      <div [ngClass]="[restoreReceivedModel.show ? 'overlay-v' : 'overlay-h']">
        <div [ngClass]="[restoreReceivedModel.show ? 'show-pop-v' : 'show-pop-h']">
          <div class="model-box">
            <div class="mb-1">
              <div class="mb-1-1"></div>
              <div class="mb-1-2" (click)="closeRestoreReceived()">X</div>
            </div>
            <div class="mb-1">
              <div>
                {{ restoreReceivedModel.title }}
              </div>
            </div>
            <div class="mb-2">
              <div class="mb-2-1">
                <button type="button" (click)="restoreExpressInterestReceived(restoreReceivedModel)" color="primary"
                  pButton>
                  YES
                </button>
              </div>
              <div class="mb-2-2">
                <button type="button" (click)="closeRestoreReceived()" color="primary" pButton>
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- ---------This section is for restore received received ------ -->

    <!-- ---------This section is for delete send ------ -->
    <ng-template [ngIf]="showExpressSendDelete">
      <div [ngClass]="[deleteSendModel.show ? 'overlay-v' : 'overlay-h']">
        <div [ngClass]="[deleteSendModel.show ? 'show-pop-v' : 'show-pop-h']">
          <div class="model-box">
            <div class="mb-1">
              <div class="mb-1-1"></div>
              <div class="mb-1-2" (click)="closeDeleteSend()">X</div>
            </div>
            <div class="mb-1">
              <div>
                {{ deleteSendModel.title }}
              </div>
            </div>
            <div class="mb-2">
              <div class="mb-2-1">
                <button type="button" (click)="deleteExpressSend(deleteSendModel)" color="primary" pButton>
                  YES
                </button>
              </div>
              <div class="mb-2-2">
                <button type="button" (click)="closeDeleteSend()" color="primary" pButton>
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- ---------This section is for delete send ------ -->

    <!-- ---------This section is for restore send ------ -->
    <ng-template [ngIf]="showExpressSendRestore">
      <div [ngClass]="[restoreSendModel.show ? 'overlay-v' : 'overlay-h']">
        <div [ngClass]="[restoreSendModel.show ? 'show-pop-v' : 'show-pop-h']">
          <div class="model-box">
            <div class="mb-1">
              <div class="mb-1-1"></div>
              <div class="mb-1-2" (click)="closeRestoreReceived()">X</div>
            </div>
            <div class="mb-1">
              <div>
                {{ restoreSendModel.title }}
              </div>
            </div>
            <div class="mb-2">
              <div class="mb-2-1">
                <button type="button" (click)="restoreExpressInterestSent(restoreSendModel)" color="primary" pButton>
                  YES
                </button>
              </div>
              <div class="mb-2-2">
                <button type="button" (click)="closeRestoreSend()" color="primary" pButton>
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- ---------This section is for restore send ------ -->
    <p-toast position="bottom-right"></p-toast>
    <p-confirmPopup></p-confirmPopup>
    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>





    <ng-template #mobilePortrait>
      <div *ngFor="let grid of pagedRecords" class="flex flex-column">
        <div class="flex flex-row">
          <div class="col-10">
            <img [src]="grid.thumbNailImage" class="thumbnail-image" (click)="
            makeitbig(
              grid.candidateProfileImageTmb,
              grid.userFromID
            )
          " />
          </div>
          <div class="col-2 action-box flex justify-content-center">

            <div *ngIf="grid?.showIconEngine">
              <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS" (click)="setShowIcon(grid)"
                tooltipStyleClass="custom-tooltip"></i>
            </div>
            <div *ngIf="!grid.showIconEngine" class="icon-set" @fadeInOutFast>
              <div class="ibo-1">
                <!-- <img pTooltip="DELETE PERMANENTLY" tooltipPosition="top"
                  src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                  (click)="openSendDeleteModal($event, grid)" tooltipStyleClass="custom-tooltip"/> -->


                <div (click)="openSendDeleteModal($event, grid)" pTooltip="DELETE PERMANENTLY" tooltipPosition="left"
                  tooltipStyleClass="custom-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                    stroke="#4b4453" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-trash-2 zoom-icon add-cursor">
                    <path d="M3 6h18" />
                    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                    <line x1="10" x2="10" y1="11" y2="17" />
                    <line x1="14" x2="14" y1="11" y2="17" />
                  </svg>
                </div>
              </div>
              <div class="ibo-2">
                <div pTooltip="RESTORE" tooltipPosition="left" (click)="openSendRestoreModal($event, grid)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                    stroke="#00c9a7" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-archive-restore zoom-icon add-cursor">
                    <rect width="20" height="5" x="2" y="3" rx="1" />
                    <path d="M4 8v11a2 2 0 0 0 2 2h2" />
                    <path d="M20 8v11a2 2 0 0 1-2 2h-2" />
                    <path d="m9 15 3-3 3 3" />
                    <path d="M12 12v9" />
                  </svg>
                </div>

              </div>
              <div class="ibo-3"></div>
              <div class="ibo-4"></div>
            </div>

          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Name</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.UserToName }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ID</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.userTo }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Age</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.age }} Years</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Religion</div>
          <div class="col-8">
            <strong> : {{ grid.christaintype }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Height</div>
          <div class="col-8">
            <strong> : {{ grid.height }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">DATE</div>
          <div class="col-8">
            <strong> : {{ grid.userExpressedDate | date : "medium" }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">STATUS</div>
          <div class="col-8">
            <strong> : {{ grid.messageStatus }}</strong>
          </div>
        </div>
        <p-divider></p-divider>
      </div>
    </ng-template>

    <ng-template #desktopLandscape>
      <p-table [value]="pagedRecords" [(selection)]="selectedMessageItems">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>PHOTO</th>
            <th pSortableColumn="userToID"> <p-sortIcon field="userToID"></p-sortIcon>FROM</th>
            <th pSortableColumn="userExpressedDate"> <p-sortIcon field="userExpressedDate"></p-sortIcon>DATE
            </th>
            <th>STATUS</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td>
              <p-tableCheckbox [value]="row"></p-tableCheckbox>
            </td>
            <td>
              <div *ngIf="row.thumbNailImage">
                <img [src]="row.thumbNailImage" class="thumbnail-image" (click)="
                  makeitbig(
                    row.candidateProfileImageTmb,
                    row.userFromID
                  )
                " />
              </div>
            </td>
            <td>
              <div class="user-info">
                <div class="ui-1">
                  {{ row.userToID }}
                </div>
                <div class="ui-2">{{ row.userTo }}</div>
                <div class="ui-3">
                  {{ row.age }} Years/{{ row.height }}/{{
                  row.christaintype
                  }}
                </div>
              </div>
            </td>
            <td>
              <div class="date-info">
                <div class="di-1">
                  {{ row.userExpressedDate | date : "medium" }}
                </div>
                <div class="di-2"></div>
              </div>
            </td>
            <td>
              <div class="date-info">
                <div class="di-1">{{ row.messageStatus }}</div>
                <div class="di-2">
                  (Changed On: {{ row.messageStatusChangeDate }})
                </div>
              </div>
            </td>
            <td>
              <div class="action-box-2 text-right">

                <!-- <div *ngIf="row?.showIconEngine">
                  <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS"
                    (click)="setShowIcon(row)"></i>
                </div>
                <div *ngIf="!row.showIconEngine" class="icon-set" @fadeInOutFast>
                
                </div> -->


                <div class="ibo-1">


                  <div (click)="openSendDeleteModal($event, row)" pTooltip="DELETE PERMANENTLY" tooltipPosition="left"
                    tooltipStyleClass="custom-tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                      stroke="#4b4453" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-trash-2 zoom-icon add-cursor">
                      <path d="M3 6h18" />
                      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                      <line x1="10" x2="10" y1="11" y2="17" />
                      <line x1="14" x2="14" y1="11" y2="17" />
                    </svg>
                  </div>


                </div>
                <div class="ibo-2">

                  <div pTooltip="RESTORE" tooltipPosition="left" (click)="openSendRestoreModal($event, row)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                      stroke="#00c9a7" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-archive-restore zoom-icon add-cursor">
                      <rect width="20" height="5" x="2" y="3" rx="1" />
                      <path d="M4 8v11a2 2 0 0 0 2 2h2" />
                      <path d="M20 8v11a2 2 0 0 1-2 2h-2" />
                      <path d="m9 15 3-3 3 3" />
                      <path d="M12 12v9" />
                    </svg>
                  </div>
                </div>
                <div class="ibo-3"></div>
                <div class="ibo-4"></div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="caption">
          <div style="font-family: 'ComfortaaRegular' ;">
            Total Records: {{sortedDataSend?.length}}
          </div>
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template #mobilePortraitFooter>
      <div class="flex py-1">
        <div class="col-12">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </div>
      </div>
    </ng-template>

    <ng-template #desktopLandscapeFooter>
      <div class="flex py-1">
        <div class="col-1 align-footer">
          <div *ngIf="sortedDataSend != undefined">
            <span class="label label-primary">Total Count {{ totalRecords }}</span>
          </div>
        </div>
        <div class="col-9">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </div>
        <div class="col-2">
          <div class="text-right">
            <button pButton *ngIf="
                sortedDataSend != undefined && sortedDataSend.length > 0
              " (click)="openMultiDeleteModal()">
              Delete
            </button>
          </div>
        </div>
      </div>
    </ng-template>


  </ng-template>
</page>