<page>
  <ng-template>
    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>
    <div class="text-block">

      <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center">EXPRESS INTEREST SENT <i class="pi pi-send"></i></div>

          <div *ngIf="showSearchIcon">
            <i class="pi pi-search super-search-icon" (click)="showSearchIcon=!showSearchIcon"></i>
          </div>
          <div *ngIf="!showSearchIcon" @fadeInOut class="panel-search">

            <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
              (ngModelChange)="globalSearch($event)" />

          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-header-container">
        </div>
        <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">

          <div class="control-section standard-content">
            <div *ngIf="sortedData?.length === 0" class="no-value-text-ic">
              No express interests sent
            </div>
            <ng-container *ngIf="sortedData?.length>0">
              <ng-container [ngSwitch]="currentDeviceState">

                <ng-container *ngSwitchCase="'desktop-landscape'">
                  <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'mobile-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'tablet-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>

              </ng-container>
            </ng-container>
          </div>
          <div class="grid-footer">
            <p-divider />
            <ng-container [ngSwitch]="currentDeviceState">

              <ng-container *ngSwitchCase="'desktop-landscape'">
                <ng-container *ngTemplateOutlet="desktopLandscapeFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'mobile-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'tablet-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>

            </ng-container>
          </div>
        </div>
      </div>


      <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
        <large-image-window [tc]="imageToDisplay"></large-image-window>
      </p-dialog>

      <p-dialog [header]="vmsg.header" [(visible)]="showMessage" [breakpoints]="{ '960px': '75vw' }"
        [style]="{ width: '20vw' }" [draggable]="false" [resizable]="false">
        <p>
          {{ vmsg.modelContent }}
        </p>
        <ng-template pTemplate="footer">
          <p-button (click)="showMessage = false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
      </p-dialog>

      <p-confirmPopup></p-confirmPopup>
      <p-toast position="bottom-right"></p-toast>
    </div>
    <ng-template #cellTemplate let-value let-customMessage="customMessage">
      <td>
        <ng-container *ngIf="value !== null && value !== undefined; else naTemplate">
          <ng-container *ngIf="value !== 'not yet'; else notYetTemplate">
            <ng-container *ngIf="isDate(value); else valueTemplate">
              {{ value | date: 'short' }}
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #naTemplate>{{ customMessage || 'Not available' }}</ng-template>
        <ng-template #notYetTemplate>Not yet</ng-template>
        <ng-template #valueTemplate>{{ value }}</ng-template>
      </td>
    </ng-template>

    <ng-template #mobilePortrait>
      <div *ngFor="let grid of pagedRecords" class="flex flex-column">
        <div class="flex flex-row">
          <div class="col-11">
            <img [src]="grid.thumbNailImage" class="thumbnail-image" (click)="
            makeitbig(grid.candidateProfileImageTmb, grid.userFromID)
          " />
          </div>
          <div class="col-1">
            <div class="action-box flex justify-content-between md:justify-content-start">
              <card-member-actions [userAction]="{
                fromId: grid?.userFrom,
                userToId: grid?.userTo,
                userToName: grid?.UserToName?.toUpperCase(),
                ShowSendMessage: false,
                sendMessageText: sendMessageText ?? '',
                Id: grid.Id,
                tableToDelete:'ExpressInterestSend'
              }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>
            </div>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Name</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.UserToName }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ID</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.userTo }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Age</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.age ? grid.age + ' Years' : '' }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Religion</div>
          <div class="col-8">
            <strong> : {{ grid.christaintype }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Height</div>
          <div class="col-8">
            <strong> : {{ grid.height }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Message</div>
          <div class="col-8 text-left">
            @if(grid.acceptanceStatus){
            {{grid?.textMessageFromUser}}
            }
            @else{
            : <i class="pi pi-eye" (click)="viewMessage(grid)" pTooltip="VIEW MESSAGE" tooltipPosition="top"
              tooltipStyleClass="custom-tooltip"></i>
            }
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Expressed On</div>
          <div class="col-8">
            <strong> : {{ grid.userExpressedOn | date : "medium" }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ACCEPTED</div>
          <div class="col-8">
            <strong> :
              @if(grid.acceptanceStatus){
              Yes
              }
              @else{
              Not Yet
              }
            </strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ACCEPTED ON</div>
          <div class="col-8">
            <strong> : <ng-container
                *ngTemplateOutlet="cellTemplate; context: { $implicit: grid.acceptedByRecipientOn }"></ng-container></strong>
          </div>
        </div>
        <p-divider></p-divider>
      </div>
    </ng-template>

    <ng-template #desktopLandscape>
      <p-table [value]="pagedRecords" [(selection)]="selectedMessageItems">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>PHOTO</th>
            <th pSortableColumn="userTo"> <p-sortIcon field="userTo"></p-sortIcon> ID</th>
            <th pSortableColumn="UserToName"> <p-sortIcon field="UserToName"></p-sortIcon> TO</th>
            <th pSortableColumn="age"> <p-sortIcon field="age"></p-sortIcon> AGE</th>
            <th pSortableColumn="height"> <p-sortIcon field="height"></p-sortIcon> HEIGHT</th>
            <th pSortableColumn="christaintype"> <p-sortIcon field="christaintype"></p-sortIcon> DENOMINATION
            </th>
            <th pSortableColumn="userExpressedOn"> <p-sortIcon field="userExpressedOn"></p-sortIcon> EXPRESSED
              ON
            </th>
            <th>VIEWED</th>
            <th>VIEWED ON </th>
            <th>ACCEPTED</th>
            <th pSortableColumn="acceptedByRecipientOn"> <p-sortIcon field="acceptedByRecipientOn"></p-sortIcon>ACCEPTED
              ON</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td>
              <p-tableCheckbox [value]="row"></p-tableCheckbox>
            </td>
            <td>
              <img [src]="row.thumbNailImage" class="thumbnail-image" (click)="
                makeitbig(row.candidateProfileImageTmb, row.userFromID)
              " />
            </td>
            <td>
              {{ row.userTo }}
            </td>
            <td> {{ row.UserToName}} </td>
            <td> {{ row.age ? row.age + ' Years' : '' }} </td>
            <td> {{ row.height}} </td>
            <td> <ng-container
                *ngTemplateOutlet="cellTemplate; context: { $implicit: row.christaintype }"></ng-container>
            </td>
            <td>
              {{ row.userExpressedOn | date : "short" }}
            </td>
            <td>
              <div class="date-info">
                <div class="di-1">@if(row.messageStatus==='Seen'){
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                    stroke="#3498DB" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-eye">
                    <path
                      d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0" />
                    <circle cx="12" cy="12" r="3" />
                  </svg>
                  }@else{
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                    stroke="#95A5A6" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-eye-off">
                    <path
                      d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49" />
                    <path d="M14.084 14.158a3 3 0 0 1-4.242-4.242" />
                    <path
                      d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143" />
                    <path d="m2 2 20 20" />
                  </svg>
                  }
                </div>
              </div>
            </td>
            <td>
              <ng-container
                *ngTemplateOutlet="cellTemplate; context: { $implicit: row.messageViewedOn }"></ng-container>
            </td>

            <td>
              @if(row.acceptanceStatus){
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="#3498DB" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-thumbs-up">
                <path d="M7 10v12" />
                <path
                  d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z" />
              </svg>
              }@else{
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="#FF6B6B" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-thumbs-down">
                <path d="M17 14V2" />
                <path
                  d="M9 18.12 10 14H4.17a2 2 0 0 1-1.92-2.56l2.33-8A2 2 0 0 1 6.5 2H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.76a2 2 0 0 0-1.79 1.11L12 22a3.13 3.13 0 0 1-3-3.88Z" />
              </svg>
              }
            </td>

            <td>
              <ng-container
                *ngTemplateOutlet="cellTemplate; context: { $implicit: row.acceptedByRecipientOn }"></ng-container>
            </td>
            <td>
              <div class="action-box-2 text-right">
                <card-member-actions [userAction]="{
                  fromId: row?.userFrom,
                  userToId: row?.userTo,
                  userToName: row?.UserToName?.toUpperCase(),
                  ShowSendMessage: false,
                  sendMessageText: sendMessageText ?? '',
                  Id: row.Id,
                  tableToDelete:'ExpressInterestSend'
                }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>

              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="caption">
          <div style="font-family: 'ComfortaaRegular' ;">
            Total Records: {{messageData?.length}}
          </div>
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template #mobilePortraitFooter>
      <div class="flex py-1">
        <div class="col-12">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </div>
      </div>
    </ng-template>

    <ng-template #desktopLandscapeFooter>
      <div class="flex py-1">
        <div class="col-1 align-footer">
          <div *ngIf="messageData != undefined">
            <span class="label label-primary">Total Count {{ messageData?.length }}</span>
          </div>
        </div>
        <div class="col-9">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </div>
        <div class="col-2 text-right">
          <button pButton *ngIf="messageData != undefined && messageData.length > 0" (click)="openMultiDeleteModal()">
            Delete
          </button>
        </div>
      </div>
    </ng-template>

  </ng-template>
</page>