import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef } from '@angular/core';
import { IndividualChatmodel } from 'src/app/dto/IndividualChatmodel';
import { ReceiverChatWindowComponentComponent } from '../receiver-chat-window-component/receiver-chat-window-component.component';
import { SenderChatWindowComponentComponent } from '../sender-chat-window-component/sender-chat-window-component.component';

@Injectable({
    providedIn: 'root'
})
export class DynamicComponentService {
    // public chatComponents: ComponentRef<IndividualChatWindowComponent>[] = [];
    public senderChatComponents: ComponentRef<SenderChatWindowComponentComponent>[] = [];
    public receiverChatComponents: ComponentRef<ReceiverChatWindowComponentComponent>[] = [];
    private readonly MAX_CHATS = 4;
    private readonly CHAT_WIDTH = 300; // px
    private readonly CHAT_MARGIN = 10; // px

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) { }

    createSenderChatWindow(userData: IndividualChatmodel): void {
        // Check if a chat window for the given user already exists
        const existingComponent = this.senderChatComponents.find(comp => comp.instance.userData.RecipientId === userData.SenderId);
        if (existingComponent) {
            // If it exists, bring it to the front
            this.focusChatWindowSender(existingComponent);
            return;
        }

        if (this.senderChatComponents.length >= this.MAX_CHATS) {
            console.warn('Maximum number of chat windows reached');
            return;
        }

        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(SenderChatWindowComponentComponent)
            .create(this.injector);

        const domElem = (componentRef.hostView as EmbeddedViewRef<SenderChatWindowComponentComponent>).rootNodes[0] as HTMLElement;
        domElem.style.transform = 'translateX(100%)';

        if (domElem.shadowRoot) {
            const style = document.createElement('style');
            style.textContent = `              
             @import url('/assets/primeicons/primeicons.css');
             .pi {
               font-family: 'PrimeIcons';
             }              
           `;
            domElem.shadowRoot.appendChild(style);
        }

        document.body.appendChild(domElem);

        componentRef.instance.userData = { ...userData };
        componentRef.instance.messages = [];
        componentRef.instance.close.subscribe(() => this.removeChatWindowSender(componentRef));

        this.senderChatComponents.push(componentRef);

        this.positionChatWindowsSender();

        this.appRef.attachView(componentRef.hostView);

        // Trigger slide-in animation
        setTimeout(() => {
            domElem.style.transform = 'translateX(0)';
        }, 50);
    }

    createReceiverChatWindow(userData: IndividualChatmodel): void {
        // Check if a chat window for the given user already exists
        const existingComponent = this.receiverChatComponents.find(comp => comp.instance.userData.RecipientId === userData.SenderId);
        if (existingComponent) {
            // If it exists, bring it to the front
            this.focusChatWindowReciever(existingComponent);
            return;
        }

        if (this.senderChatComponents.length >= this.MAX_CHATS) {
            console.warn('Maximum number of chat windows reached');
            return;
        }

        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(ReceiverChatWindowComponentComponent)
            .create(this.injector);

        const domElem = (componentRef.hostView as EmbeddedViewRef<ReceiverChatWindowComponentComponent>).rootNodes[0] as HTMLElement;
        domElem.style.transform = 'translateX(100%)';

        if (domElem.shadowRoot) {
            const style = document.createElement('style');
            style.textContent = `              
             @import url('/assets/primeicons/primeicons.css');
             .pi {
               font-family: 'PrimeIcons';
             }              
           `;
            domElem.shadowRoot.appendChild(style);
        }

        document.body.appendChild(domElem);

        componentRef.instance.userData = { ...userData };
        componentRef.instance.messages = [];
        componentRef.instance.close.subscribe(() => this.removeChatWindowReciever(componentRef));

        this.receiverChatComponents.push(componentRef);

        this.positionChatWindowsReciever();

        this.appRef.attachView(componentRef.hostView);

        // Trigger slide-in animation
        setTimeout(() => {
            domElem.style.transform = 'translateX(0)';
        }, 50);
    }
    createChatWindow(userData: IndividualChatmodel): void {

    }

    private removeChatWindowSender(componentRef: ComponentRef<SenderChatWindowComponentComponent>): void {
        const index = this.senderChatComponents.indexOf(componentRef);
        if (index > -1) {
            this.senderChatComponents.splice(index, 1);
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
            this.positionChatWindowsSender();
        }
    }

    private removeChatWindowReciever(componentRef: ComponentRef<ReceiverChatWindowComponentComponent>): void {
        const index = this.receiverChatComponents.indexOf(componentRef);
        if (index > -1) {
            this.receiverChatComponents.splice(index, 1);
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
            this.positionChatWindowsReciever();
        }
    }

    private positionChatWindowsSender(): void {
        const MAIN_CHAT_LIST_WIDTH = 300; // Space occupied by the main chat list on the right
        const totalChatWindows = this.senderChatComponents.length;

        this.senderChatComponents.forEach((comp, index) => {
            const elem = (comp.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

            // Set the right offset to account for the main chat list's width
            const rightPosition = MAIN_CHAT_LIST_WIDTH + (index * (this.CHAT_WIDTH + this.CHAT_MARGIN)) + 20; // 20px padding from the edge of main chat list

            elem.style.right = `${rightPosition}px`;
            elem.style.bottom = '0px';
            elem.style.position = 'fixed';
            elem.style.zIndex = `${1000 + index}`;

            // Slide-in animation for smooth appearance
            elem.style.transform = 'translateX(0)';
            elem.style.transition = 'transform 0.3s ease-in-out';
        });
    }

    private positionChatWindowsReciever(): void {
        const MAIN_CHAT_LIST_WIDTH = 300; // Space occupied by the main chat list on the right
        const totalChatWindows = this.receiverChatComponents.length;

        this.receiverChatComponents.forEach((comp, index) => {
            const elem = (comp.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

            // Set the right offset to account for the main chat list's width
            const rightPosition = MAIN_CHAT_LIST_WIDTH + (index * (this.CHAT_WIDTH + this.CHAT_MARGIN)) + 20; // 20px padding from the edge of main chat list

            elem.style.right = `${rightPosition}px`;
            elem.style.bottom = '0px';
            elem.style.position = 'fixed';
            elem.style.zIndex = `${1000 + index}`;

            // Slide-in animation for smooth appearance
            elem.style.transform = 'translateX(0)';
            elem.style.transition = 'transform 0.3s ease-in-out';
        });
    }

    // Bring an existing chat window to the front
    public focusChatWindowSender(componentRef: ComponentRef<SenderChatWindowComponentComponent>): void {
        const index = this.senderChatComponents.indexOf(componentRef);
        if (index > -1) {
            // Reorder component in the array to move it to the end (front)
            this.senderChatComponents.splice(index, 1);
            this.senderChatComponents.push(componentRef);
            this.positionChatWindowsSender();
        }
    }

    public focusChatWindowReciever(componentRef: ComponentRef<ReceiverChatWindowComponentComponent>): void {
        const index = this.receiverChatComponents.indexOf(componentRef);
        if (index > -1) {
            // Reorder component in the array to move it to the end (front)
            this.receiverChatComponents.splice(index, 1);
            this.receiverChatComponents.push(componentRef);
            this.positionChatWindowsReciever();
        }
    }

    public closeAllChatWindows(): void {
        // Close all sender chat windows
        this.senderChatComponents.forEach(componentRef => {
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
        });
        this.senderChatComponents = []; // Clear the array
    
        // Close all receiver chat windows
        this.receiverChatComponents.forEach(componentRef => {
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
        });
        this.receiverChatComponents = []; // Clear the array
    
        console.log('All chat windows closed.');
    }
}
