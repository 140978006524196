import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActionResponse } from 'src/app/dto/ActionResponse';
import { SendMessageGeneric } from 'src/app/dto/SendMessageGeneric';
import { UserAction } from "src/app/dto/UserAction";
import { SendMessageComponent } from '../send-message/send-message.component';
import { ExpressInterestCarrier } from 'src/app/dto/ExpressInterestCarrier';
import { DatePipe } from '@angular/common';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { Commonservice } from '../servicecenter/commonservice.component';
import { ExpressInterestModel } from 'src/app/dto/ExpressInterestModel';
import { ExpressInterestSendModel } from 'src/app/dto/ExpressInterestSendModel';
import { RemoveBookmarkModel } from 'src/app/dto/RemoveBookmarkModel';
import { DeleteModel } from 'src/app/dto/DeleteModel';
import { APP_CONSTANTS } from '../contants/app.constants';
import { IndividualChatmodel } from 'src/app/dto/IndividualChatmodel';
import { DynamicComponentService } from '../servicecenter/DynamicComponentService';

@Component({
  selector: 'card-member-actions',
  templateUrl: './card-member-actions.component.html',
  styleUrl: './card-member-actions.component.css',
  providers: [MessageService, ConfirmationService]
})
export class CardMemberActionsComponent implements OnInit {
  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;
  @Input() userAction: UserAction;
  @Output() triggerParentRefresh = new EventEmitter<boolean>();
  userloggedIn: string;
  mtd: ExpressInterestModel;
  confirmationTitle: string = "Confirmation";
  expressInterestModel: ExpressInterestSendModel;
  removeBookmarkModel: RemoveBookmarkModel;
  smg: SendMessageGeneric;
  selectedUserId: string;
  viewProfileVisible: boolean = false;
  nameOfPersonsProfileBeingSeen: string;


  constructor(private messageService: MessageService, private confirmationService: ConfirmationService, private datePipe: DatePipe, private localStore: LocalStore, private commonservice: Commonservice, private dynamicComponentService: DynamicComponentService) {
  }

  ngOnInit(): void {
    const self = this;
    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
  }

  expressInterest() {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = self.userAction.userToId;
    eic.userToName = self.userAction.userToName;
    self.mtd = self.commonservice.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure express interest?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  showSendMessage() {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = this.userAction.userToId;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.userAction.ShowSendMessage = true;
    self.commonservice.SetSendMessage(self.smg);
  }

  viewProfile() {
    this.viewProfileVisible = true;
    this.nameOfPersonsProfileBeingSeen = this.userAction.userToName;
    this.selectedUserId = this.userAction.userToId;
  }

  deleteBookmark() {
    console.log(`Deleted bookmark with ID: ${this.userAction.Id}`);
  }

  eventPostSubmit(event: ActionResponse) {
    this.userAction.ShowSendMessage = false;
    if (event.ActionState == true) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    this.userAction.ShowSendMessage = event.ShowSendMessage;
  }

  sendExpressInterest(event) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonservice.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  deleteRecord() {
    const self = this;
    const id = parseInt(self.userAction.Id);
    switch (this.userAction.tableToDelete) {
      case 'BookMark':
        self.confirmationService.confirm({
          target: event.target,
          message: APP_CONSTANTS.QuestionBeforeBookMarkRemoval,
          accept: () => {
            self.removeBookMark(id);
          }
        });
        break;
      case 'MessageInbox':
        self.confirmationService.confirm({
          target: event.target,
          message: APP_CONSTANTS.QuestionBeforeDeleting,
          accept: () => {
            self.deleteInboxItem(id);
          }
        });
        break;
      case 'MessageSent':
        self.confirmationService.confirm({
          target: event.target,
          message: APP_CONSTANTS.QuestionBeforeDeleting,
          accept: () => {
            self.deleteInBoxSentItem(id);
          }
        });
        break;
      case 'ExpressInterestRecieved':
        self.confirmationService.confirm({
          target: event.target,
          message: APP_CONSTANTS.QuestionBeforeDeleting,
          accept: () => {
            self.deleteExpressInterestRecieved(id);
          }
        });
        break;
      case 'ExpressInterestSend':
        self.confirmationService.confirm({
          target: event.target,
          message: APP_CONSTANTS.QuestionBeforeDeleting,
          accept: () => {
            self.deleteExpressInterestSend(id);
          }
        });
        break;
      default:
        break;
    }

  }


  removeBookMark(idToDelete: number) {
    const self = this;
    self.removeBookmarkModel = {} as RemoveBookmarkModel;
    self.removeBookmarkModel.bookmarkId = idToDelete.toString();
    self.commonservice.removeBookMark(self.removeBookmarkModel).subscribe(data => {
      if (data) {
        self.triggerParentRefresh.next(true);
        self.triggerParentRefresh.complete();
        this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Message });
      }
    });
  }

  deleteInboxItem(idToDelete: number) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = idToDelete.toString();
    self.commonservice.deleteSelectedMessages(deleteId).subscribe(
      data => {
        if (data.DeleteWorked) {
          self.triggerParentRefresh.next(true);
          self.triggerParentRefresh.complete();
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
        }
      }
    );
  }

  deleteInBoxSentItem(idToDelete: number) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = idToDelete.toString();
    self.commonservice.deleteFromOutBox(deleteId).subscribe(
      data => {
        if (data.DeleteWorked) {
          self.triggerParentRefresh.next(true);
          self.triggerParentRefresh.complete();
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
        }
      }
    );
  }

  deleteExpressInterestRecieved(idToDelete: number) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = idToDelete.toString();
    self.commonservice.deleteMessageFromExpressReceived(deleteId).subscribe(
      data => {
        if (data.DeleteWorked) {
          self.triggerParentRefresh.next(true);
          self.triggerParentRefresh.complete();
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
        }
      }
    );
  }

  deleteExpressInterestSend(idToDelete: number) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = idToDelete.toString();
    self.commonservice.deleteRecordExpressSent(deleteId).subscribe(
      data => {
        if (data.DeleteWorked) {
          self.triggerParentRefresh.next(true);
          self.triggerParentRefresh.complete();
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
        }
      }
    );
  }

  invokeChat() {
    const self = this;
    let icm: IndividualChatmodel = {
      AddText: "",
      RecipientDisplayName: self.userAction.userToName,
      IsExpanded: false,
      Messages: [],
      ShowLoader: false,
      SenderId: this.userloggedIn,
      RecipientId: self.userAction.userToId,
      SenderDisplayName: ""
    };
    this.dynamicComponentService.createSenderChatWindow(icm);
  }

}
