import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'publicpaymentoptions',
  templateUrl: './publicpaymentoptions.component.html',
  styleUrls: ['./publicpaymentoptions.component.scss']
})
export class PublicpaymentoptionsComponent {
  benefits: Benefit[] = [
    { text: '20 Contact Details of Members per day', icon: 'pi pi-users' },
    { text: 'Send and receive custom messages', icon: 'pi pi-comments' },
    { text: 'Bookmarks, Custom Messages, Express Interest continues to exist through out your membership plan', icon: 'pi pi-bookmark' },
    { text: 'Lock & Unlock images from image section to prevent showing up in view profile image gallery', icon: 'pi pi-images' },
    { text: 'Chat directly accessible to the other members cards & searches', icon: 'pi pi-comment' },
    { text: 'Image match with other members', icon: 'pi pi-image' }
  ];

  plans: PricingPlan[] = [
    {
      type: "Best Value",
      duration: "1 Year's Subscription",
      price: 1200,
      bgColor: "var(--orange-50)",
      iconColor: "var(--orange-500)",
      recommended: true
    },
    {
      type: "Six Months",
      duration: "6 months Subscription",
      price: 1200,
      bgColor: "var(--green-50)",
      iconColor: "var(--green-500)"
    },
    {
      type: "Annually",
      duration: "1 Year's Subscription",
      price: 2400,
      bgColor: "var(--purple-50)",
      iconColor: "var(--purple-500)"
    },
    {
      type: "Monthly",
      duration: "Subscription",
      price: 200,
      bgColor: "var(--blue-50)",
      iconColor: "var(--blue-500)"
    }
  ];

}

interface PricingPlan {
  type: string;
  duration: string;
  price: number;
  bgColor: string;
  iconColor: string;
  recommended?: boolean;
}

interface Benefit {
  text: string;
  icon: string;
}