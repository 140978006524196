<page>
  <ng-template>
    <ng-containter *ngIf="showLiteLoader">
      <loader></loader>
    </ng-containter>



    <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
      <div class="flex justify-content-between flex-wrap">
        <div class="flex align-items-center">MESSAGE INBOX <i class="pi pi-inbox"></i></div>

        <div *ngIf="showSearchIcon">
          <i class="pi pi-search super-search-icon" (click)="showSearchIcon=!showSearchIcon"></i>
        </div>
        <div *ngIf="!showSearchIcon" @fadeInOut class="panel-search">

          <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
            (ngModelChange)="globalSearch($event)" />

        </div>
      </div>
    </div>    
    <div class="panel panel-default b-r-1">
      <div class="panel-header-container">

        <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">
          <div class="control-section  standard-content">



            <div *ngIf="sortedData?.length === 0" class="no-value-text-ic">
              No messages in Inbox
            </div>

            <div *ngIf="pagedRecords.length > 0">


              <ng-container [ngSwitch]="currentDeviceState">


                <ng-container *ngSwitchCase="'desktop-landscape'">
                  <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
                </ng-container>


                <ng-container *ngSwitchCase="'mobile-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'tablet-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>

              </ng-container>


            </div>
          </div>
          <div class="grid-footer" *ngIf="sortedData?.length > 0">
            <p-divider />
            <ng-container [ngSwitch]="currentDeviceState">

              <ng-container *ngSwitchCase="'desktop-landscape'">
                <ng-container *ngTemplateOutlet="desktopLandscapeFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'mobile-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'tablet-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>

            </ng-container>
          </div>
        </div>
      </div>

    </div>

    <p-dialog [header]="vmsg.header" [(visible)]="showMessage" [breakpoints]="{ '960px': '75vw' }"
      [style]="{ width: '20vw' }" [draggable]="false" [resizable]="false">
      <p>
        {{ vmsg.modelContent }}
      </p>
      <ng-template pTemplate="footer">
        <p-button (click)="showMessage = false" label="Keep it pending" styleClass="p-button-text"></p-button>
        <p-button (click)="acceptInterest(vmsg)" label="I accept" styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>

    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>

    <p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
      <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
        (OnSubmit)="eventPostSubmit($event)"></send-message>
    </p-dialog>

    <p-toast position="bottom-right"></p-toast>
    <p-confirmPopup></p-confirmPopup>

    <ng-template #cellTemplate let-value>
      <td>
        @if (value !== null && value !== undefined) {
        @if (isDate) {
        {{ value | date: "short" }}
        } @else {
        {{ value }}
        }
        } @else {
        Not available
        }
      </td>
    </ng-template>


    <ng-template #mobilePortrait>
      <div *ngFor="let grid of pagedRecords" class="flex flex-column">
        <div class="flex flex-row">
          <div class="col-11">
            <img [src]="grid.thumbNailImage" (click)="
                  makeitbig(
                    grid.candidateProfileImageTmb,
                    grid.userFromID
                  )
                " class="profile-image thumbnail-image" />
          </div>
          <div class="col-1 action-box1 ">
            <div class="action-box flex justify-content-between md:justify-content-start">
              <!-- <div *ngIf="grid?.showIconEngine">
                <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS"
                  (click)="setShowIcon(grid)"></i>
              </div>
              <div *ngIf="!grid.showIconEngine" class="icon-set" @fadeInOutFast>
               
              </div> -->

              <card-member-actions [userAction]="{
                fromId: grid.userToID,
                userToId: grid.userFromID,
                userToName: grid.UserFromName.toUpperCase(),
                ShowSendMessage: false,
                sendMessageText: sendMessageText ?? '',
                Id: grid.inboxID,
                tableToDelete:'MessageInbox'
              }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>
            </div>


            <!-- <card-member-actions [userAction]="{
              fromId: grid.userToID,
              userToId: grid.userFromID,
              userToName: grid.UserFromName.toUpperCase(),
              ShowSendMessage: false,
              sendMessageText: sendMessageText ?? '',
              Id: grid.inboxID,
              tableToDelete:'MessageInbox'
            }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions> -->
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Name</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.UserFromName }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ID</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.userFromID }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Age</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.age ? grid.age + ' Years' : '' }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Religion</div>
          <div class="col-8">
            <strong> : {{ grid.christaintype }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Edu Qualification</div>
          <div class="col-8">
            <strong> : {{ grid.Education }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Occupation</div>
          <div class="col-8">
            <strong> : {{ grid.Occupation }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Work Place</div>
          <div class="col-8">
            <strong> : {{ grid.Workplace }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Looking For</div>
          <div class="col-8">
            <strong>: {{ grid.PartnerExpectations }}</strong>
          </div>
        </div>
        <p-divider></p-divider>
      </div>
    </ng-template>

    <ng-template #desktopLandscape>
      <p-table [value]="pagedRecords" [(selection)]="selectedMessageItems">
        <ng-template pTemplate="header">
          <tr class="text-block">
            <th style="width: 4rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>PHOTO</th>
            <th pSortableColumn="UserFromName">FROM <p-sortIcon field="UserFromName"></p-sortIcon></th>
            <th pSortableColumn="userFromID">SCM ID <p-sortIcon field="userFromID"></p-sortIcon></th>
            <th pSortableColumn="age"><p-sortIcon field="age"></p-sortIcon>AGE</th>
            <th pSortableColumn="height"><p-sortIcon field="height"></p-sortIcon>HEIGHT</th>
            <th pSortableColumn="christaintype"><p-sortIcon field="christaintype"></p-sortIcon>DENOMINATION
            </th>
            <th pSortableColumn="expressedOn"><p-sortIcon field="expressedOn"></p-sortIcon>RECEIVED ON</th>
            <th>ACCEPTED</th>
            <th>ACCEPTED ON</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-grid>
          <tr>
            <td>
              <p-tableCheckbox [value]="grid"></p-tableCheckbox>
            </td>
            <td>
              <img [src]="grid.thumbNailImage" (click)="
            makeitbig(
              grid.candidateProfileImageTmb,
              grid.userFromID
            )
          " class="profile-image thumbnail-image" />
            </td>
            <td class="text-block">
              {{ grid.UserFromName }}
            </td>
            <td class="text-block">
              {{ grid.userFromID}}
            </td>
            <td class="text-block">
              {{ grid.age ? grid.age + ' Years' : '' }}
            </td>
            <td class="text-block">
              {{ grid.height}}
            </td>
            <td class="text-block">
              <ng-container *ngTemplateOutlet="cellTemplate; context: { $implicit: grid.christaintype }"></ng-container>
            </td>
            <td class="text-block">
              {{ grid.expressedOn | date : "medium" }}
            </td>
            <td class="text-block">
              <div class="hl">
                @if(grid?.acceptanceStatus!=undefined && grid?.acceptanceStatus==='1')
                {
                <div pTooltip="Accepted" tooltipStyleClass="custom-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                    stroke="#ff9b04" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-thumbs-up">
                    <path d="M7 10v12" />
                    <path
                      d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z" />
                  </svg>
                </div>
                }
                @else if(grid?.acceptanceStatus===undefined || grid?.acceptanceStatus===null)
                {
                <div class="hl">
                  <div>
                    Waiting..
                  </div>
                  <div class="di-1">
                    <img pTooltip="VIEW MESSAGE" tooltipPosition="top"
                      src="../../../../assets/image/newicons/icons8-visible-67.png" class="rc-ito-2" alt=""
                      (click)="viewMessage(grid)" tooltipStyleClass="custom-tooltip" />
                  </div>
                </div>
                }
                @else{
                <div class="hl">
                  <div>
                    Waiting..
                  </div>
                  <div class="di-1">
                    <img pTooltip="VIEW MESSAGE" tooltipPosition="top"
                      src="../../../../assets/image/newicons/icons8-visible-67.png" class="rc-ito-2" alt=""
                      (click)="viewMessage(grid)" tooltipStyleClass="custom-tooltip" />
                  </div>
                </div>
                }
              </div>
            </td>
            <td class="text-block">
              @if(grid.acceptanceOn){
              {{ grid.acceptanceOn | date : "medium"}}
              }
              @if(grid?.acceptanceOn ===undefined || grid?.acceptanceStatus===null){
              Waiting..
              }
            </td>
            <td class="text-right">
              <div class="action-box-2 text-right">

                <action-box-with-bookmark [userAction]="{
                  fromId: grid.userToID,
                  userToId: grid.userFromID,
                  userToName: grid.UserFromName.toUpperCase(),
                  ShowSendMessage: false,
                  sendMessageText: sendMessageText ?? '',
                  Id: grid.inboxID,
                  tableToDelete:'MessageInbox'
                }" (triggerParentRefresh)="triggerParentRefresh($event)"></action-box-with-bookmark>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="caption">
          Total Records: {{totalRecords}}
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template #mobilePortraitFooter>
      <div class="col-12 justify-content-center">
        <ng-container *ngIf="sortedData?.length > 0">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </ng-container>
      </div>
    </ng-template>

    <ng-template #desktopLandscapeFooter>
      <div class="flex py-1">
        <div class="col-1 align-footer">
          <span class="label label-primary">Total Count {{ sortedData?.length }}</span>
        </div>

        <div class="col-9 justify-content-center">
          <ng-container *ngIf="sortedData?.length > 0">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true"
              [pageLinks]="5" [showPageLinks]="true" [showJumpToPageDropdown]="false"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
          </ng-container>
        </div>

        <div class="col-2 text-right">
          <button pButton *ngIf="sortedData?.length > 0" (click)="openMultiDeleteModal()">
            Delete
          </button>
        </div>
      </div>
    </ng-template>

    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>






  </ng-template>
</page>