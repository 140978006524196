<div class="barelist">
    <div>
  
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Communication Address
        </div>
        <div class='col-md-9'>
          {{mc.CandidateCommunicationAddress}}
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Candidate's Present Address
        </div>
        <div class='col-md-9'>
  
          {{mc.CandidateCurrentAddress}}
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Residence Phone No
        </div>
        <div class='col-md-9'>
          {{mc.ContactNumber}}
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Office Phone No
        </div>
        <div class='col-md-9'>
          {{mc.CandidateOfficePhonenNumber}}
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Mobile Number I
        </div>
        <div class='col-md-9'>
          {{mc.MobileNumber}}
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Mobile Number II
        </div>
        <div class='col-md-9'>
          {{mc.CandidateAltNumber}}
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Preferred Time to Call
        </div>
        <div class='col-md-9'>
          <!-- Not Mentioned -->
          {{mc.CandidatePreferredTimeToCall}}
  
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Reference Person's Name & Address in 'India'
        </div>
        <div class='col-md-9'>
  
  
          {{mc.ReferenceNameInKerala}} 
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Relationship with Candidate
        </div>
        <div class='col-md-9'>
          {{mc.RelationshipWithKeralaReference}}
        </div>
      </div>
    </div>
    <div>
      <hr>
      <div class='row'>
        <div class='col-md-3 left-sub-title'>
          Ref. Person's Ph.No./Mob.No
        </div>
        <div class='col-md-9'>
          {{mc.ReferencePhoneNumberInKerala}}
        </div>
      </div>
    </div>
  </div>