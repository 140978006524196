<publicpage-register>
  <!-- <div class="grid">
    <div class="col-2">
      <div class="heading">Buy Membership</div>
    </div>
    <div class="col-4"></div>
  </div>
  <hr class="my-hr">
  <div class="grid">
    <div class="col-12">
      <div class="login-payment-title">What you get when in subscription:</div>
      <div class="grid">
        <div class="col-6">
          <ul class="login-payment-fature-list">
            <li>Contact Details (Verified &amp; Unvarified)</li>
            <li>Paid Membership for the Duration Selected</li>
            <li>Help and Support from customer support executives</li>
            <li>Maximum Security from our end</li>
            <li>Get better visibility in search results</li>
            <li>Online Matrimonial Services</li>
            <li>Send and receive personalized messages</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <hr class="my-hr">
  <p-fieldset legend="Purchase new subscription">
    <div class="text-block">
      <div class="grid">
        <div class="col-4">
          <div class="heading"></div>
        </div>
        <div class="col-4"></div>
      </div>
      <hr class="my-hr">
      <div class="grid">
        <div class="col-6">
          <div class="grid">
            <div class="col-1"></div>
            <div class="col-3 subtitles">Best Value:</div>
            <div class="col-2">

              <div class="rupee-icons best-value">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none"
                  stroke="#c2410c" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-indian-rupee">
                  <path d="M6 3h12" />
                  <path d="M6 8h12" />
                  <path d="m6 13 8.5 8" />
                  <path d="M6 13h3" />
                  <path d="M9 13c6.667 0 6.667-10 0-10" />
                </svg>

              </div>


            </div>
            <div class="col-4">
              <ul class="payment-amount-list">
                <li>1 Years Subscription</li>
                <li>₹1200</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="grid">
            <div class="col-1"></div>
            <div class="col-3 subtitles">Annualy:</div>
            <div class="col-2">
              <div class="rupee-icons one-year">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none"
                  stroke="#7e22ce" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-indian-rupee">
                  <path d="M6 3h12" />
                  <path d="M6 8h12" />
                  <path d="m6 13 8.5 8" />
                  <path d="M6 13h3" />
                  <path d="M9 13c6.667 0 6.667-10 0-10" />
                </svg>

              </div>


            </div>
            <div class="col-4">
              <ul class="payment-amount-list">
                <li>1 Years Subscription</li>
                <li>₹2400</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-hr">
      <div class="grid">
        <div class="col-6">
          <div class="grid">
            <div class="col-1"></div>
            <div class="col-3 subtitles">Six Months:</div>
            <div class="col-2">
              <div class="rupee-icons six-month">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none"
                  stroke="#15803d" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-indian-rupee">
                  <path d="M6 3h12" />
                  <path d="M6 8h12" />
                  <path d="m6 13 8.5 8" />
                  <path d="M6 13h3" />
                  <path d="M9 13c6.667 0 6.667-10 0-10" />
                </svg>
              </div>
            </div>
            <div class="col-4">
              <ul class="payment-amount-list">
                <li>6 months Subscription</li>
                <li>₹1200</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="grid">
            <div class="col-1"></div>
            <div class="col-3 subtitles">Monthly:</div>
            <div class="col-2">
              <div class="rupee-icons one-month">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none"
                  stroke="#1d4ed8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-indian-rupee">
                  <path d="M6 3h12" />
                  <path d="M6 8h12" />
                  <path d="m6 13 8.5 8" />
                  <path d="M6 13h3" />
                  <path d="M9 13c6.667 0 6.667-10 0-10" />
                </svg>
              </div>
            </div>
            <div class="col-4">
              <ul class="payment-amount-list">
                <li>Subscription</li>
                <li>₹200</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-fieldset> -->


  <!-- pricing.component.html -->
  <div class="pricing-container">
    <!-- Header Section -->
    <div class="header-section">
      <h2 class="text-900 text-3xl font-medium mb-4">
        <i class="pi pi-check-circle text-primary mr-2"></i>
        SUBSCRIPTIONS
      </h2>

      <!-- Benefits List -->
      <p-fieldset styleClass="benefits-card mb-4" legend="What you get when in subscription">
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-6" *ngFor="let benefit of benefits">
            <div class="flex align-items-center mb-3">
              <i [class]="benefit.icon + ' text-primary mr-2 text-xl'"></i>
              <span class="text-700">{{ benefit.text }}</span>
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>

    <!-- Purchase Button -->
    <div class="flex justify-content-center mb-5">
      <p-button label="Purchase new subscription" icon="pi pi-shopping-cart"
        styleClass="p-button-rounded p-button-primary">
      </p-button>
    </div>

    <!-- Pricing Plans Grid -->
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-3" *ngFor="let plan of plans">
        <p-card [styleClass]="'plan-card ' + (plan.recommended ? 'recommended' : '')">
          <ng-template pTemplate="header">
            <div class="plan-header" [style.background-color]="plan.bgColor">
              <div *ngIf="plan.recommended" class="recommended-badge">
                Most Popular
              </div>
              <h3 class="text-xl font-medium mb-2">{{ plan.type }}</h3>
              <p class="text-600 m-0">{{ plan.duration }}</p>
            </div>
          </ng-template>

          <div class="pricing-content">
            <div class="price-section">
              <span class="currency">₹</span>
              <span class="amount">{{ plan.price }}</span>
            </div>

            <p-button [label]="'Choose ' + plan.type" styleClass="p-button-outlined w-full"
              [style]="{'color': plan.iconColor}">
            </p-button>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</publicpage-register>