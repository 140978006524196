import { Component, OnInit, Renderer2 } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';

@Component({
  selector: 'loginhomeoffers',
  templateUrl: './loginhomeoffers.component.html',
  styleUrls: ['./loginhomeoffers.component.css']
})
export class LoginhomeoffersComponent implements OnInit {
  currentDeviceState: string = "";
  currentClass: string;
  userAgent: any;
  browserType = 0; // 0 for default, 1 for Chrome, 2 for Edge
  constructor(private signalStateService: SignalStateService, private renderer: Renderer2) { }

  ngOnInit() {
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();
    this.userAgent = navigator.userAgent;
    if (this.userAgent.includes('Chrome') && !this.userAgent.includes('Edg')) {
      this.browserType = 1;
    } else if (this.userAgent.includes('Edg')) {
      this.browserType = 2;
    } 
    
    if (navigator.userAgent.includes('OPR')) {
      this.browserType = 3;
    }

    this.signalStateService.setValue('browserType', this.browserType);
  }

  setScreenClass(screenType: string) {
    switch (screenType) {
      case "desktop-landscape":
        this.currentClass = 'image';
        break;
      case "mobile-portrait":
        this.currentClass = 'image-mobile';
        break;
      case "tablet-portrait":
        this.currentClass = 'image-mobile';
        break;
      default:
        this.currentClass = 'image';
        break;
    }
  }
}
