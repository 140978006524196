import { Injectable } from "@angular/core";
import { LoginUserDto } from "src/app/dto/LoginUserDto";
import { LoginResponseModel } from "src/app/dto/LoginResponseModel";
import { Observable } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ActionResponseModel } from "src/app/dto/ActionResponseModel";
import { LambdaService } from "./LambdaService";

@Injectable()
export class CommonNewservice {
    connect = "";
    constructor(private httpClient: HttpClient, private lambdaService: LambdaService) {
        this.connect = this.lambdaService.getLambdaUrl('internalApiCalls');
    }

    login(user: LoginUserDto): Observable<LoginResponseModel> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'skip-interceptor': ''
            })
        };
        const self = this;
        const theresult = this.httpClient.post<LoginResponseModel>(self.connect + '/OpenApi/AuthenticateUser', user, httpOptions);
        return theresult;
    }

    sendResetLink(userEmail: string): Observable<ActionResponseModel> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'skip-interceptor': ''
            })
        };
        const self = this;
        const theresult = this.httpClient.get<ActionResponseModel>(self.connect + '/OpenApi/SendResetLink?userEmail=' + userEmail);
        return theresult;
    }
}
