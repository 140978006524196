import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PrefetchBackGroundService } from '../../reusable/servicecenter/PrefetchBackGroundService';

@Component({
  selector: 'publicpage',
  templateUrl: './publicpage.component.html',
  styleUrls: ['./publicpage.component.css']
})
export class PublicpageComponent implements OnInit, OnDestroy {
  currentDeviceState: string;
  pageHeaderClass: string;

  constructor(private pbgs: PrefetchBackGroundService, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this));
    window.addEventListener('orientationchange', this.checkScreenSize.bind(this));
    //this.setScreenClass(this.currentDeviceState);
  }

  private checkScreenSize(): void {
    this.currentDeviceState = this.deviceService?.deviceType?.toLowerCase() + "-" + this.deviceService?.orientation?.toLowerCase();
    this.pbgs.storeResolutionRelatedData(this.currentDeviceState);    
  }


  setScreenClass(screenType: string) {
    switch (screenType) {
      case "desktop-landscape":
        this.pageHeaderClass = 'public-page-header';
        break;
      case "mobile-portrait":
        this.pageHeaderClass = 'public-page-header-mobile';
        break;
      case "tablet-portrait":
        this.pageHeaderClass = 'public-page-header-mobile';
        break;
      default:
        this.pageHeaderClass = 'public-page-header';
        break;
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.checkScreenSize.bind(this));
    window.removeEventListener('orientationchange', this.checkScreenSize.bind(this));
  }
  
}
