<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>
<p-card>
  <div class='flex py-1'>
    <div class='col-2'>
      <div class="elegant-frame">
        <img [src]=mc.CandidateProfileImageReal (click)="makeitbig(mc.candidateProfileImageTmb,mc.CandidateUserId)"
          class="profile-image" id="ctl00_ContentPlaceHolder1_pic1View">
      </div>
    </div>
    <div class='col-10 pl-5'>
      <div class="barelist">
        <div>
          <div class="barelist">

            <div class='flex'>
              <div class="col-3 mt-3">
                I'am {{mc.CandidateName}}
              </div>
              <div class="col-9 mt-2" style="display: flex; justify-content: end;">
                <div class='flex' style="width: 40%;">

                  <div class="col-3">
                    <button class="custom-button bookmark-button" (click)="bookmarkprofile()" pTooltip="Bookmark"
                      tooltipPosition="top" tooltipStyleClass="custom-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-bookmark-plus">
                        <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z" />
                        <line x1="12" x2="12" y1="7" y2="13" />
                        <line x1="15" x2="9" y1="10" y2="10" />
                      </svg>
                      <div class="pl-2"> Bookmark</div>
                    </button>
                  </div>

                  <div class="col-3  ml-5">

                    <button class="custom-button message-button" (click)="showSendDialogue()"
                      pTooltip="Send Custom message" tooltipPosition="top" tooltipStyleClass="custom-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-message-square-text">
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                        <path d="M13 8H7" />
                        <path d="M17 12H7" />
                      </svg>
                      <div class="pl-2"> Message</div>
                    </button>

                  </div>





                  <div class="col-3  ml-5">

                    <button class="custom-button propose-button text-sky-600 bg-sky-50" pTooltip="PROPOSE"
                      tooltipPosition="top" (click)="expressInterestPop($event)" tooltipStyleClass="custom-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-hand-heart">
                        <path d="M11 14h2a2 2 0 1 0 0-4h-3c-.6 0-1.1.2-1.4.6L3 16" />
                        <path
                          d="m7 20 1.6-1.4c.3-.4.8-.6 1.4-.6h4c1.1 0 2.1-.4 2.8-1.2l4.6-4.4a2 2 0 0 0-2.75-2.91l-4.2 3.9" />
                        <path d="m2 15 6 6" />
                        <path
                          d="M19.5 8.5c.7-.7 1.5-1.6 1.5-2.7A2.73 2.73 0 0 0 16 4a2.78 2.78 0 0 0-5 1.8c0 1.2.8 2 1.5 2.8L16 12Z" />
                      </svg>
                      <span> Propose</span>
                    </button>

                  </div>


                </div>







              </div>
            </div>

            <p-divider></p-divider>
            Last Login Date: {{mc.CandidateLastLogin | date:"MM/dd/yy" }}
            <p-divider></p-divider>
          </div>

        </div>
        <div>


          <div class="barelist">


            <div>
              <div class='flex py-1'>
                <div class='col-3'>
                  Denomination
                </div>
                <div class='col-9'>
                  {{mc.CandidateDenomination}}
                </div>
              </div>
            </div>
            <p-divider></p-divider>
            <div>
              <div class='flex py-1'>
                <div class='col-3'>
                  Education
                </div>
                <div class='col-9'>
                  {{mc.CandidateEducationSubject}}
                </div>
              </div>
            </div>
            <p-divider></p-divider>
          </div>

        </div>

      </div>
    </div>
  </div>
  <ImageModelWindow [tc]=imageToDisplay></ImageModelWindow>  
  <p-dialog></p-dialog>
  <p-toast position="bottom-right"></p-toast>
  <p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
    <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
      (OnSubmit)="eventPostSubmit($event)"></send-message>
  </p-dialog>
</p-card>